import styled, { css } from 'styled-components/macro'

import { Row, Col, Grid } from 'react-flexbox-grid'
import { minXs, maxSm } from '../../styles/breakPoints'

interface Props {
  active: number
}

export const Container = styled(Grid)`
  div.active {
    position: relative;
    z-index: 1;
  }

  @media (${minXs}) and (${maxSm}) {
    width: 680px;
  }
`

interface Visible {
  visible: boolean
}
export const PaginationContainer = styled.div<Visible>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props: Visible) => (props.visible ? '30px auto' : '15px auto')};
  display: ${(props: Visible) => (props.visible ? 'flex' : 'none')};
  max-width: 980px;

  p.totalProducts {
    font-size: 14px;
    font-weight: 300;
    margin-left: 15px;

    span {
      margin-left: 8px;
    }
  }

  ul.pagination-nav {
    display: flex;
    margin: 0;

    li {
      list-style: none;
      font-size: 14px;
      padding: 2px 8px;
      font-weight: 300;
      cursor: pointer;

      &.disabled {
        opacity: 0.3 !important;
      }

      &.pagination-active {
        font-weight: 700;
      }

      a {
        color: #1f2f3b;
        text-decoration: none;

        svg {
          margin-top: 3px;
        }
      }
    }
  }

  @media (${maxSm}) {
    margin: 15px 0;
  }
`
export const TextInput = styled.div`
  position: relative;

  button {
    position: absolute;
    right: 15px;
    bottom: 6px;
    transition: 0.3s;
  }

  button:hover {
    transition: 0.3s;
    transform: scale(1.2);
  }

  input {
    width: 220px;
    height: 28px;
    padding: 5px;
    border-radius: 10px;
    border: solid 1px #ccc;
    margin: 25px 5px 0px;

    ::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
    ::-moz-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
    :-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
    :-moz-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
  }
`
export const SubHeaderStyled = styled(Grid)<Props>`
  div.container {
    display: flex;
    justify-content: flex-end;
    margin: auto;
    width: 90%;
    align-items: center;
    max-width: 975px;
  }
  div.filterClient {
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
  }
  div.actions {
    margin: 0 auto;
    min-width: 285px;
    height: 80px;
    max-width: 948px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 90%;

    button {
      transition: all 0.4s;
    }

    h3 {
      font-size: 20px;
    }

    ${(props) =>
      props.active &&
      css`
        button {
          border-color: #d45b5b;
          color: #d45b5b;
        }
      `}
  }

  @media (${maxSm}) {
    div.container {
      flex-direction: column;
    }
    div.actions {
      justify-content: space-between;
      height: 60px;

      h3 {
        font-size: 20px;
      }

      button {
        max-width: 100px;
      }
    }
  }
`

export const PageInfo = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;

  p {
    text-align: center;
    font-size: 17px;
    font-weight: 300;

    strong {
      font-weight: 500;
    }
  }

  @media (${maxSm}) {
    height: 60px;

    p {
      display: none;
    }
  }
`

export const ApproveAllConfirmation = styled(Col)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;

  h3 {
    margin-bottom: 20px;
    font-weight: 500;
  }

  button {
    margin: 0 auto;
  }

  @media (${maxSm}) {
    padding: 0px 30px;

    h3 {
      margin-bottom: 5px;
    }
  }
`

export const ReviewItem = styled(Row)`
  padding: 0px 0px;
  border-radius: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin-bottom: 100px;

  h4 {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }
`
export const ReviewTitle = styled(Col)`
  display: flex;
  align-items: center;
  height: 90px;

  img {
    height: 60px;
    margin-right: 20px;
    border-radius: 10px;
  }

  h4 {
    font-size: 22px;
    font-weight: 500px;
    margin-right: 10.5px;
  }

  small {
    font-size: 12px;
    font-weight: 300;
  }
`

export const ProductInfo = styled(Col)`
  div.content {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 30px 0;

    div.info {
      margin-left: 20px;
    }

    strong.link {
      text-decoration: underline;
      max-width: 200px;
    }

    strong.guidelines {
      display: block;
      margin: 20px 0 10px;
      font-size: 18px;
    }

    p {
      font-size: 18px;
      overflow: hidden;
      word-break: break-all;
      font-weight: 300;
      margin-top: 5px;

      strong {
        margin-left: 20px;
      }
    }

    img {
      width: 50%;
      max-width: 220px;
      display: block;
      border-radius: 20px;
      margin-right: 10px;
    }
  }
`
export const ReviewInfo = styled(Col)`
  div.images {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    border-left: solid 0.1px #eee;
    padding-left: 10px;

    img {
      display: block;
      width: 48%;
      max-width: 220px;
      border-radius: 10px;
    }
  }
`
export const ReviewActions = styled(Col)`
  display: flex;
  justify-content: flex-end;
`

export const PendingReviewsEmpty = styled(Row)`
  justify-content: center;
  text-align: center;
  transition: 2s;
  transition-delay: 2s;
  margin-top: 20px;

  h4 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 300;
    color: #000;
    margin-bottom: 40px;

    strong {
      font-size: 20px;
    }
  }

  img {
    height: 30vh;
    max-height: 300px;
  }

  a {
    margin: 25px auto;
  }

  @media (${maxSm}) {
    padding: 0 30px;

    h4 {
      font-size: 18px;
    }

    img {
      height: 22vh;
    }
  }
`

export const ResponsiveContainer = styled.div`
  max-width: 980px;
  margin: auto;

  @media (min-width: 320px) and (max-width: 767px) {
    width: 90vw;
  }
`
