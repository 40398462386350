import styled from 'styled-components/macro'
import { maxSm } from '../../styles/breakPoints'

export const Header = styled.header`
  display: flex;
  width: 100%;
  height: 70px;
  padding: 0 25px;
  background: #1f2f3b;
  justify-content: space-between;
  align-items: center;

  div.brand {
    display: flex;
    align-items: center;
    z-index: 20;

    a {
      display: block;
      width: 108px;
      margin-top: 5px;

      img {
        width: 100%;
      }
    }
  }

  div.brand span {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    margin-left: 19px;
  }

  button.menu-toggle {
    display: none;
    background: none;
    border: none;
  }

  /* responsive */
  @media (${maxSm}) {
    nav {
      display: none;
    }

    span {
      display: none;
    }

    div.menu-section {
      button.menu-toggle {
        display: block;
        width: 30px;
        height: 32px;

        .first-bar,
        .middle-bar,
        .last-bar {
          background-color: #fff;
          height: 3px;
          width: 100%;
          transition-duration: 0.3s;

          & + div {
            margin: 7px auto;
          }
        }
      }

      &.on {
        position: absolute;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;

        background-color: #1f2f3b;
        z-index: 10;

        button.menu-toggle {
          position: absolute;
          margin-right: 20px;

          right: 0;
          top: 15px;

          div.first-bar {
            transform: rotate(45deg) translate(7px, 7px);
          }

          div.middle-bar {
            opacity: 0;
          }

          div.last-bar {
            transform: rotate(-45deg) translate(7px, -8px);
          }
        }

        nav {
          display: block;

          ul {
            display: block;
            margin-top: 100px;

            li:first-child {
              display: block;
              color: #fff;
              font-size: 25px;
              font-weight: 500;
              margin-bottom: 20px;
            }

            li a {
              transition-duration: 0.5s;
              font-size: 22px;
              line-height: 35px;
            }
          }
        }
      }
    }
  }
`
export const Nav = styled.nav`
  ul {
    display: flex;
    list-style: none;
    font-size: 16px;

    li {
      margin-left: 40px;

      &:first-child {
        display: none;
      }

      a {
        text-decoration: none;
        color: #fff;
        font-weight: 300;
        transition: 0.2s;
        border-bottom: solid 1px transparent;

        &:hover {
          border-bottom: solid 1px #fff;
        }
      }
    }
  }
`
