import React from 'react'

import { CustomRadioButton } from './styles'

interface Props {
  id?: string
  label?: string
  name?: string
  value?: string
  checked?: boolean
  onChange?: (e) => void
}

const RadioButton = ({ id, label, name, checked, value, onChange }: Props) => (
  <CustomRadioButton className="radio-container">
    {label}
    <input
      type="radio"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
    />
    <span className="checkmark" />
  </CustomRadioButton>
)

export default RadioButton
