import styled from 'styled-components'

import { maxXs } from '../../../styles/breakPoints'

export const ModalContent = styled.div`
  position: relative;
  background-color: #fefefe;
  margin: 12vh auto 0 auto;
  padding: 35px;
  border: 1px solid #888;
  max-width: 720px;
  border: solid 1px #707070;
  border-radius: 10px;

  @media (${maxXs}) {
    padding: 25px;
  }
`

export const ModalBody = styled.div`
  margin: 0 auto;
  min-height: 300px;
  border-radius: 10px;
  background-color: #ffffff;
  sub {
    height: 23px;
    opacity: 0.5;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
`

export const ModalFooter = styled.div`
  button.align-left {
    margin-left: auto;
  }

  button.align-right {
    margin-right: auto;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // width: 100vw;

  button {
    width: 110px;
    height: 40px;
    border-radius: 5px;
    //opacity: 0.5;
    background-color: #1f2f3b;
    color: white;
    border: none;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .btn-cancel {
    background: none;
    border: none;
    outline: none;
    filter: none;
    color: #1f2f3b;
    margin-right: 5px;
  }
`
export const Container = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: row;

  h4 {
    width: 100%;
    height: 23px;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .left {
    height: auto;
    display: flex;
    flex-direction: column;

    .btn-insert {
      position: relative;
      background: none;
      border: none;
      width: 140px;
      height: 28px;
      border-radius: 5px;
      border: solid 1px #1f2f3b;
      background-color: #1f2f3b;
      color: #fff;
    }
    h4 {
      text-align: left;
    }
  }

  .right {
    width: 38vh;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    input {
      width: 140px;
      height: 28px;
      border-radius: 5px;
      border: solid 1px #1f2f3b;
      float: right;
      padding: 10px;
      ::placeholder {
        font-size: 12px;
        font-weight: normal;
        color: #1f2f3b;
        opacity: 1;
      }
    }

    div {
      margin-top: 10px;
      padding: 0;
      width: 100%;
    }
    p {
      width: 100%;
      display: table;
      padding: 5px;
    }
    label {
      vertical-align: -webkit-baseline-middle;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }
`
