import React, { ReactNode } from 'react'

import { FaTimes } from 'react-icons/fa'

import { RejectMenuStyled } from './styles'

interface Props {
  show: boolean
  onClick: () => void
  children: ReactNode
}

export default function RejectMenu({ show, onClick, children }: Props) {
  return (
    show && (
      <RejectMenuStyled>
        <button className="close-reject-menu" type="button" onClick={onClick}>
          <FaTimes size={20} />
        </button>
        {children}
      </RejectMenuStyled>
    )
  )
}
