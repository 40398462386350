import styled from 'styled-components/macro'

export const ApproveConfirmation = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 90px;
  margin: 15px 0;

  h3 {
    margin-bottom: 10px;
  }
`
