import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../store'

import { getSummaryRequest } from '../../store/modules/user/actions'

import Loading from '../../components/Loading'

import ContextLoading from '../../components/Loading/ContextLoading'

import { BgContainer, CenterBox } from './styles'
import { setNameOrSku, setStatus } from '../../store/modules/filters/actions'

function Dashboard() {
  const { t, ready } = useTranslation(['dashboard/index'])
  const { type: userType, summary } = useSelector(
    (state: ApplicationState) => state.user
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSummaryRequest())
  }, [dispatch])
  return (
    <Loading>
      <ContextLoading isLoading={!ready}>
        <BgContainer hasHeader>
          <CenterBox>
            <div>
              <h1>{t('welcome')}</h1>

              <h3>
                {userType === 'CLIENT'
                  ? t('yourSkuPendences')
                  : t('clientSkuPendences')}
              </h3>

              <p>{t('redirectInfo')}</p>
            </div>
            <Link to="/review" className="btn-default">
              {t('actionButtons.pendingReviews', {
                count: summary?.pendingReviewsCount,
              })}
            </Link>
            <Link
              to="/models"
              className="btn-outline"
              onClick={() => {
                dispatch(setStatus('MISSING_INFORMATION'))
                dispatch(setNameOrSku(''))
              }}
            >
              {t('actionButtons.missingInformation', {
                count: summary?.missingInformationsCount,
              })}
            </Link>
          </CenterBox>
        </BgContainer>
      </ContextLoading>
    </Loading>
  )
}

export default Dashboard
