import { Reducer } from 'redux'
import { ModelsState, ModelTypes } from './types'

const INITIAL_STATE: ModelsState = {
  models: [],
  totalItemsCount: 0,
  baseModels: [],
  processingFilter: false,
}

const reducer: Reducer<ModelsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModelTypes.LOAD_MODELS_SUCCESS:
      return {
        ...state,
        models: action.payload.models,
        totalItemsCount: action.payload.totalItemsCount,
      }
    case ModelTypes.LOAD_BASE_MODELS_SUCCESS:
      return { ...state, baseModels: action.payload.models }
    case ModelTypes.PROCESS_FILTER_TOGGLE:
      return { ...state, processingFilter: action.payload.status }
    default:
      return state
  }
}

export default reducer
