import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import RadioButton from '../../_html/RadioButton'

import { Reasons } from './styles'

import ContextLoading from '../../Loading/ContextLoading'

import {
  REJECT_REASONS,
  RejectData,
} from '../../../store/modules/product/types'

interface Props {
  handleGetData: Function
  initData?: RejectData
  showTitle?: boolean
  showRejectButton?: boolean
  rejectButtonText?: string
  handleReject?: () => void
}

export default function RejectProduct({
  handleGetData,
  initData,
  showTitle = false,
  showRejectButton = false,
  rejectButtonText = '',
  handleReject,
}: Props) {
  const { t, ready } = useTranslation([
    'components/products/reasonsToReject/index',
  ])

  const [reason, setReason] = useState(REJECT_REASONS.WRONG_TEXTURE)
  const [comments, setComments] = useState('')
  const [isValidData, setIsValidData] = useState(false)
  const minLength = 3

  useEffect(() => {
    setReason(REJECT_REASONS.WRONG_TEXTURE)
    setComments('')
  }, [])

  useEffect(() => {
    setComments(initData?.comments ?? '')
    setReason(initData?.reason ?? REJECT_REASONS.WRONG_TEXTURE)
  }, [initData])

  useEffect(() => {
    const checkValidData = comments.length > minLength

    handleGetData({
      reason,
      comments,
      isValidData: checkValidData,
    })

    setIsValidData(checkValidData)
  }, [reason, comments, handleGetData])

  return (
    <ContextLoading isLoading={!ready}>
      <Reasons ready={ready}>
        {showTitle && <h2 className="title">{t('title')}</h2>}
        <div className="reject-data">
          <div className="reasons">
            <div className="first-group">
              <RadioButton
                label={t('wrongTexture')}
                name="reason"
                onChange={(e) => setReason(e.target.value)}
                value={REJECT_REASONS.WRONG_TEXTURE}
                checked={reason === REJECT_REASONS.WRONG_TEXTURE}
              />
              <RadioButton
                label={t('wrongColor')}
                name="reason"
                onChange={(e) => setReason(e.target.value)}
                value={REJECT_REASONS.WRONG_COLOR}
                checked={reason === REJECT_REASONS.WRONG_COLOR}
              />
              <RadioButton
                label={t('transparentFaces')}
                onChange={(e) => setReason(e.target.value)}
                name="reason"
                value={REJECT_REASONS.TRANSPARENT_FACES}
                checked={reason === REJECT_REASONS.TRANSPARENT_FACES}
              />
              <RadioButton
                label={t('badModeling')}
                onChange={(e) => setReason(e.target.value)}
                name="reason"
                value={REJECT_REASONS.BAD_MODELING}
                checked={reason === REJECT_REASONS.BAD_MODELING}
              />
            </div>
            <div className="last-group">
              <RadioButton
                label={t('wrongScale')}
                onChange={(e) => setReason(e.target.value)}
                name="reason"
                value={REJECT_REASONS.WRONG_SCALE}
                checked={reason === REJECT_REASONS.WRONG_SCALE}
              />
              <RadioButton
                label={t('wrongPivotPoint')}
                onChange={(e) => setReason(e.target.value)}
                name="reason"
                value={REJECT_REASONS.WRONG_PIVOT_POINT}
                checked={reason === REJECT_REASONS.WRONG_PIVOT_POINT}
              />
              <RadioButton
                label={t('others')}
                onChange={(e) => setReason(e.target.value)}
                name="reason"
                value={REJECT_REASONS.OTHERS}
                checked={reason === REJECT_REASONS.OTHERS}
              />
            </div>
          </div>
          <textarea
            placeholder={t('commentsPlaceholder')}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>
        {showRejectButton && (
          <button
            type="button"
            className="btn btn-reject"
            onClick={handleReject}
            disabled={!isValidData}
          >
            {rejectButtonText}
          </button>
        )}
      </Reasons>
    </ContextLoading>
  )
}
