import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Auth0Lock from 'auth0-lock'
import { ApplicationState } from '../store'

import * as AuthActions from '../store/modules/auth/actions'

import { AUTH_CONFIG } from './auth0-variables'
import { Auth0Data } from '../store/modules/auth/types'

interface StateProps {
  signed: boolean
}

interface DispatchProps {
  loadSessionRequest(): void
  saveAuth0Data(data: Auth0Data): void
  setLoggedIn(logged: boolean): void
}

interface OwnProps {
  sessionType: string
  location: { hash: string }
  locale: string
  welcomeMessage: string
}

type Props = StateProps & DispatchProps & OwnProps

class Lock extends React.Component<Props> {
  public sessionType: string

  public options: object

  public lock: Auth0LockStatic

  constructor(props: Props) {
    super(props)

    const { setLoggedIn } = this.props
    setLoggedIn(false)

    this.onAuthenticated = this.onAuthenticated.bind(this)

    this.sessionType = props.sessionType

    this.options = {
      auth: {
        audience: AUTH_CONFIG.audience,
        responseType: 'token id_token',
        sso: false,
      },
      language: props.locale,
      languageDictionary: {
        title: props.welcomeMessage,
      },
      container: AUTH_CONFIG.container,
      theme: {
        logo:
          'https://real2u-public-assets.s3.amazonaws.com/images/white-background-logo-quadrado.png',
        primaryColor: '#5596d3',
      },
      allowedConnections: ['Username-Password-Authentication', 'google-oauth2'],
      allowSignUp: this.sessionType === AUTH_CONFIG.allowSignUpKey,
      allowLogin: true,
      hashCleanup: false,
      redirectUrl: AUTH_CONFIG.callbackUrl,
    }

    this.lock = new Auth0Lock(
      AUTH_CONFIG.clientId,
      AUTH_CONFIG.domain,
      this.options
    )

    this.onAuthenticated()
  }

  componentDidMount() {
    const { location } = this.props
    // Avoid showing Lock when hash is parsed.
    if (!/access_token|id_token|error/.test(location.hash)) {
      this.lock.show()
    }
  }

  onAuthenticated() {
    this.lock.on('authenticated', (authResult) => {
      const expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      )
      localStorage.setItem('access_token', authResult.accessToken)
      localStorage.setItem('id_token', authResult.idToken)
      localStorage.setItem('expires_at', expiresAt)

      this.lock.getProfile(authResult.accessToken, (err, profile) => {
        const { accessToken, idToken } = authResult

        const { sub: auth0Id, email } = profile

        const { saveAuth0Data, loadSessionRequest } = this.props

        saveAuth0Data({
          accessToken,
          idToken,
          expiresAt: authResult.idTokenPayload.exp,
          auth0Id,
          email,
        })

        loadSessionRequest()
      })
    })
  }

  render() {
    const style = { marginTop: '32px' }
    const { signed } = this.props

    return (
      !signed && (
        <div>
          <div id={AUTH_CONFIG.container} style={style} />
        </div>
      )
    )
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  signed: state.auth.signed,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AuthActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Lock)
