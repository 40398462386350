import styled from 'styled-components/macro'

export const ToggleStyled = styled.div`
  display: flex;
  align-items: center;

  .react-toggle {
    .react-toggle-track {
      background-color: #fff !important;
      border: 1px solid rgba(31, 47, 59, 0.5);
      outline: none;

      .react-toggle-track-x,
      .react-toggle-track-check {
        color: #1f2f3b;
      }
    }

    .react-toggle-thumb {
      transition: 0.4s;
      box-shadow: none;
    }

    &.react-toggle--checked .react-toggle-track {
      background: #8ec396 !important;
      border-color: transparent;
      transition: 1s;
    }
  }
`
