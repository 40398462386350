import { all, call, put, takeLatest, select, delay } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import i18n from '../../../config/i18n'

// eslint-disable-next-line import/no-cycle
import api from '../../../services/api'

import { PendingReviewsTypes, PendingReviewState } from './types'
import {
  loadPendingReviewsSuccess,
  changeInfoBarVisibility,
  setTotalItems,
  setIsUpdating,
} from './actions'
import { showModal } from '../modal/actions'
import { Product, PRODUCT_STATUS } from '../product/types'

// eslint-disable-next-line import/no-cycle
import { setProductsData } from '../product/sagas'

export function* loadUserPendingReviews(action) {
  yield i18n.loadNamespaces(['sagas', 'config'])
  const { payload } = action
  const { page, resultsPerPage, nameOrSku, customerIdFilter } = payload

  try {
    const { data: products } = yield call(
      api.get,
      `user/products/pending-reviews`,
      {
        params: {
          page,
          resultsPerPage,
          nameOrSku,
          customerIdFilter,
        },
      }
    )

    const { data, metadata } = products
    const productsData = setProductsData(data)

    const { totalItemsCount } = metadata

    yield put(changeInfoBarVisibility(productsData.length > 0))
    yield put(setTotalItems(totalItemsCount))
    yield put(loadPendingReviewsSuccess(productsData))
  } catch (error) {
    if (error.status !== 401) {
      toast.error(i18n.t('sagas:pendingReviews.loadProductsError'))
    }
  }
}

export function* updateProductStatus({ payload }: AnyAction) {
  yield put(showModal(false))

  try {
    yield call(api.post, 'product/reviews/create', payload)
  } catch (err) {
    toast.error(i18n.t('sagas:pendingReviews.updateProductStatusError'))
    return
  }

  const { products } = yield select(
    (state: PendingReviewState) => state.products
  )

  const { productId, status } = payload

  const updatedStatusProducts = products.map((product: Product) =>
    product._id === productId ? { ...product, status, visible: false } : product
  )

  const currentProducts = products.filter(
    (product: Product) => product._id !== productId
  )

  const emptyProducts = currentProducts.length

  yield put(loadPendingReviewsSuccess(updatedStatusProducts))

  yield delay(1000)
  yield put(loadPendingReviewsSuccess([]))

  if (!emptyProducts) {
    yield put(changeInfoBarVisibility(false))
  }
  yield put(loadPendingReviewsSuccess(currentProducts))
}

function* approveAllPendencies() {
  yield put(setIsUpdating(true))

  const { products } = yield select(
    (state: PendingReviewState) => state.products
  )

  const toApprove = products
    .filter(
      (product: Product) => product.status === PRODUCT_STATUS.EXTERNAL_REVIEW
    )
    .map((product: Product) => ({
      productId: product._id,
      status: PRODUCT_STATUS.RUNNING,
    }))

  toast.info(i18n.t('sagas:pendingReviews.approveAllMessage'))
  try {
    yield call(api.post, 'product/reviews/create', toApprove)
  } catch (err) {
    toast.error(i18n.t('sagas:pendingReviews.approveAllError'))
    return
  }
  toast.success(i18n.t('sagas:pendingReviews.approveAllSuccess'))

  const updatedStatusProducts = products.map((product: Product) => ({
    ...product,
    status: PRODUCT_STATUS.RUNNING,
    visible: false,
  }))

  yield put(loadPendingReviewsSuccess(updatedStatusProducts))

  yield delay(1000)
  yield put(loadPendingReviewsSuccess([]))

  yield put(changeInfoBarVisibility(false))
  yield put(setIsUpdating(false))
}

export default all([
  takeLatest(
    PendingReviewsTypes.UPDATE_PRODUCT_STATUS_REQUEST,
    updateProductStatus
  ),
  takeLatest(
    PendingReviewsTypes.LOAD_USER_PENDING_REVIEWS_REQUEST,
    loadUserPendingReviews
  ),
  takeLatest(
    PendingReviewsTypes.APPROVE_ALL_PENDENCIES_REQUEST,
    approveAllPendencies
  ),
])
