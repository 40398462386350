import styled from 'styled-components/macro'
import { Col } from 'react-flexbox-grid'
import { maxSm, minSm } from '../../styles/breakPoints'

interface Props {
  mobileFilter: boolean
}

export const SubHeaderStyled = styled.div<Props>`
  h3 {
    font-size: 22px;
  }
  div.container {
    display: flex;
    justify-content: flex-end;
    margin: auto;
    width: 90%;
    align-items: center;
    max-width: 975px;
  }

  div.filterClient {
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
  }

  @media (${minSm}) {
    .hide-content-min-sm {
      display: none !important;
    }
  }

  @media (${maxSm}) {
    padding: 0 10px;
    margin-bottom: ${({ mobileFilter }) => (mobileFilter ? '100px' : '15px')};

    .hide-content-xs {
      display: none !important;
    }
  }
`

export const Actions = styled(Col)`
  display: flex;
  position: relative;
  margin: 0 auto;
  min-width: 280px;
  height: 80px;
  justify-content: space-between;
  align-items: center;

  div.responsive-sub-header {
    display: flex;
    margin: 0 auto;
    max-width: 980px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  label {
    top: 5px;
  }

  div.controls-default {
    div.review-large {
      display: flex;
    }
  }

  div.controls-sm {
    display: none;

    button.check-btn,
    button.fa-search {
      background: transparent;
      border: none;
      font-size: 20px;
      margin-left: 20px;
      background: #fff;

      &:active {
        transition: 0.3s;
        transform: scale(1.2);
      }
    }
  }

  div.top-action {
    display: flex;

    label {
      height: 20px;
      top: 0;
      margin-bottom: 0;
    }

    span.check-all {
      display: flex;
      align-items: center;
    }

    button.check-all-back {
      display: none;
    }
  }

  div.filter {
    display: flex;
  }

  @media (${maxSm}) {
    height: 60px;

    div.controls-sm {
      display: flex;
    }

    div.top-action {
      align-items: center;
      padding-left: 10px;

      button {
        height: 30px !important;
      }

      button.check-all-back {
        display: block !important;
        position: absolute;
        left: -5px;
      }

      span {
        height: 20px;
      }
    }
  }
`

export const ProductsActions = styled.div`
  display: flex;

  button.add-product {
    height: 30px;
    line-height: 30px;
  }

  @media (${maxSm}) {
    display: none;

    &.mobile-filter {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      top: 75px;

      div.filter {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        div {
          width: 80%;
          margin-bottom: 5px;

          input {
            width: 100%;
            margin: 0;
          }
        }

        /* input, */
        select {
          width: 80%;
          margin: 5px 10px;
        }
      }

      button.add-product {
        display: none;
      }
    }
  }
`

export const ReviewActions = styled.div`
  display: flex;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
    }
  }

  @media (${maxSm}) {
    div.review-small {
      div {
        display: flex;
      }

      button.btn-cancel {
        height: 35px;
        line-height: 35px;
      }

      button.action-review {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: transparent;
        margin-left: 10px;

        span {
          display: none;
        }
      }
    }

    svg {
      display: block !important;
    }
  }
`

export const TextInput = styled.div`
  position: relative;

  button {
    position: absolute;
    right: 15px;
    bottom: 6px;
    transition: 0.3s;
  }

  button:hover {
    transition: 0.3s;
    transform: scale(1.2);
  }

  input {
    width: 220px;
    height: 28px;
    padding: 5px;
    border-radius: 10px;
    border: solid 1px #ccc;
    margin: 0 10px;

    ::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
    ::-moz-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
    :-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
    :-moz-placeholder {
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }
  }
`

export const Select = styled.select`
  width: 220px;
  height: 28px;
  padding: 5px;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  border: solid 1px #ccc;
  margin: 0 10px;
  font-weight: 300;
`
