// eslint-disable-next-line import/no-cycle
import { call, select, all, takeLatest, put } from 'redux-saga/effects'

import { AnyAction } from 'redux'
// eslint-disable-next-line import/no-cycle
import api from '../../../services/api'

import {
  acceptTermsSuccess,
  getSummarySuccess,
  getCustomersSuccess,
} from './actions'
import { UserTypes, UserState } from './types'

interface State {
  user: UserState
}

export function* acceptTerms() {
  const { id, termsAndConditionsId } = yield select(
    (state: State) => state.user
  )

  const { data: acceptResponse } = yield call(api.patch, `/users/${id}`, {
    acceptTerms: new Date(),
    termsAndConditionsId,
  })

  if (acceptResponse) {
    yield put(acceptTermsSuccess())
  }
}

export function* getSummary() {
  const { data } = yield call(api.get, '/users/summary')

  yield put(getSummarySuccess(data.summary))
}

export function* getCustomers({ payload }: AnyAction) {
  const { filters } = payload

  const { data } = yield call(api.get, '/customers', { params: filters })

  const customers = data.sort((customer1, customer2) =>
    customer1.name.localeCompare(customer2.name)
  )

  yield put(getCustomersSuccess(customers))
}
export default all([
  takeLatest(UserTypes.ACCEPT_TERMS_REQUEST, acceptTerms),
  takeLatest(UserTypes.GET_SUMMARY_REQUEST, getSummary),
  takeLatest(UserTypes.GET_CUSTOMER_REQUEST, getCustomers),
])
