import { action } from 'typesafe-actions'
import { AuthTypes, Auth0Data } from './types'
import { UserState } from '../user/types'

export const loadSessionRequest = () => action(AuthTypes.SESSION_REQUEST)
export const loadSessionSuccess = (user: UserState) =>
  action(AuthTypes.SESSION_SUCCESS, { user })
export const loadSessionFailure = () => action(AuthTypes.SESSION_FAILURE)
export const sessionExpired = () => action(AuthTypes.SESSION_EXPIRED)

export const saveAuth0Data = (data: Auth0Data) =>
  action(AuthTypes.LOAD_AUTH0_DATA, { data })
export const setLoggedIn = (signed: boolean) =>
  action(AuthTypes.SET_LOGGED, { signed })
export const logout = () => action(AuthTypes.LOGOUT)
