import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { bindActionCreators, Dispatch } from 'redux'
import * as AuthActions from '../store/modules/auth/actions'

interface StateProps {
  location: { hash: string }
}

interface DispatchProps {
  setLoggedIn(logged: boolean): void
  logout(): void
}

type Props = StateProps & DispatchProps

class Logout extends Component<Props, {}> {
  constructor(props: Props) {
    super(props)
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')

    const { logout } = this.props
    logout()
  }

  render() {
    const { location } = this.props
    const sessionType = localStorage.getItem('sessionType')
    return (
      <Redirect
        to={{
          pathname: `/login/${sessionType || ''}`,
          state: { from: location },
        }}
      />
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(AuthActions, dispatch)

export default connect(null, mapDispatchToProps)(Logout)
