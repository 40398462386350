import styled, { keyframes } from 'styled-components/macro'

const pulse = keyframes`
 0% { 
    transform: scale(0.95);
    
  }
 70% { 
   transform: scale(1.2);
   
   }
 100% { 
   transform: scale(0.95);
   }
 `

export const Loader = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  img {
    width: 40px;
    animation: ${pulse};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    opacity: 0.5;
  }
`
