import { format } from 'date-fns/esm'
import { enUS, ptBR } from 'date-fns/esm/locale'

const locales = { ptBR, enUS }

type Locales = 'ptBR' | 'enUS'

export default function (
  date: Date,
  locale: Locales = 'ptBR',
  formatStr = 'PPp'
): string {
  return format(date, formatStr, { locale: locales[locale] })
}
