export enum UserTypes {
  'ACCEPT_TERMS_REQUEST' = '@user/ACCEPT_TERMS_REQUEST',
  'ACCEPT_TERMS_SUCCESS' = '@user/ACCEPT_TERMS_SUCCESS',
  'GET_SUMMARY_REQUEST' = '@user/GET_SUMARY_REQUEST',
  'GET_SUMMARY_SUCCESS' = '@user/GET_SUMARY_SUCCESS',
  'GET_CUSTOMER_REQUEST' = '@user/GET_CUSTOMER_REQUEST',
  'GET_CUSTOMER_SUCCESS' = '@user/GET_CUSTOMER_SUCCESS',
}

export interface Summary {
  pendingReviewsCount: number
  missingInformationsCount: number
}

export interface Customer {
  name: string
  _id: string
}

export interface UserState {
  readonly id: string
  readonly type: string
  readonly name: string
  readonly email: string
  readonly termsAndConditionsUrl: string
  readonly termsAndConditionsId: string
  readonly acceptTermsAndConditionsLoading?: boolean
  readonly summary?: Summary
  readonly customerId: string
  readonly customers?: Customer[]
}
