import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'

import { useTranslation, Trans } from 'react-i18next'

import { Animated } from 'react-animated-css'

import Pagination from 'react-js-pagination'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { ApplicationState } from '../../store'

import Loading from '../../components/Loading'
import ContextLoading from '../../components/Loading/ContextLoading'

import {
  PendingReviewsEmpty,
  Container,
  ResponsiveContainer,
  PaginationContainer,
} from './styles'

import SubHeader from './SubHeader'
import ApproveAllDialog from './ApproveAllDialog'
import ReviewItem, {
  ReviewActions,
} from '../../components/products/ReviewItem/index'
import { DefaultModal } from '../../components/Modal'
import Reasons from '../../components/products/ReasonsToReject'

import { showModal } from '../../store/modules/modal/actions'
import {
  PRODUCT_STATUS,
  REJECT_REASONS,
  RejectData,
} from '../../store/modules/product/types'
import {
  loadPendingReviewsRequest,
  updateProductStatusRequest,
  approveAllPendenciesRequest,
} from '../../store/modules/pendingReviews/actions'

import wellDone from '../../assets/images/welldone/welldone-console@2x.png'
import { setPage } from '../../store/modules/filters/actions'

export default function () {
  const { t, ready } = useTranslation(['pendingReviews/index'])

  const [prodIdToReject, setProdIdToReject] = useState('')
  const [rejectData, setRejectData] = useState({} as RejectData)
  const [rejectInitData, setRejectInitData] = useState({} as RejectData)
  const [showApproveAllDialog, setShowApproveAllDialog] = useState(false)
  const [emptyReview, setEmptyReview] = useState(false)
  const [activeItem, setActiveItem] = useState('')
  const [paginationLimit] = useState(10)
  const { type: userType } = useSelector(
    (state: ApplicationState) => state.user
  )
  const products = useSelector(
    (state: ApplicationState) => state.products.products
  )
  const infoBar = useSelector(
    (state: ApplicationState) => state.products.infoBar
  )
  const dispatch = useDispatch()
  const totalItemsCount = useSelector(
    (state: ApplicationState) => state.products.totalItemsCount
  )
  const filters = useSelector((state: ApplicationState) => state.filters)
  useEffect(() => {
    dispatch(
      loadPendingReviewsRequest({
        page: filters.page,
        resultsPerPage: paginationLimit,
        nameOrSku: filters.nameOrSku,
        customerIdFilter: filters.customerIdFilter,
      })
    )
  }, [
    dispatch,
    filters.page,
    paginationLimit,
    filters.nameOrSku,
    filters.customerIdFilter,
  ])

  useEffect(() => {
    setShowApproveAllDialog(false)
    setEmptyReview(
      !products ||
        products.every((item) => item.status !== PRODUCT_STATUS.EXTERNAL_REVIEW)
    )
  }, [products, filters.page])

  function handleRejectProductDialog(prodId: string) {
    setProdIdToReject(prodId)
    dispatch(showModal(true))
  }

  function handleReject() {
    dispatch(
      updateProductStatusRequest({
        productId: prodIdToReject,
        status: PRODUCT_STATUS.REJECTED,
        ...rejectData,
      })
    )
  }

  function handleApproveAll() {
    dispatch(approveAllPendenciesRequest())
  }

  function setOverlayEffect(productStatus: string) {
    switch (productStatus) {
      case PRODUCT_STATUS.EXTERNAL_REVIEW:
        return 'none'
      case PRODUCT_STATUS.RUNNING:
      case PRODUCT_STATUS.APPROVED:
        return PRODUCT_STATUS.APPROVED
      default:
        return PRODUCT_STATUS.REJECTED
    }
  }

  const handleGetRejectData = useCallback((data) => {
    setRejectData(data)
  }, [])

  const onModalClose = useCallback(() => {
    setRejectInitData({
      comments: '',
      reason: REJECT_REASONS.WRONG_TEXTURE,
      isValidData: false,
    })
  }, [])

  return (
    <>
      <DefaultModal onClose={onModalClose}>
        <Reasons
          handleGetData={handleGetRejectData}
          initData={rejectInitData}
          showTitle
        />
        <button
          type="button"
          disabled={!rejectData.isValidData}
          onClick={handleReject}
          className="btn btn-reject align-left"
        >
          {t('reviewActions.reject')}
        </button>
      </DefaultModal>
      <SubHeader
        enableApproveAll={emptyReview}
        active={showApproveAllDialog}
        handleApproveAll={() => setShowApproveAllDialog(!showApproveAllDialog)}
      />
      <PaginationContainer
        visible={totalItemsCount > paginationLimit || filters.page > 1}
      >
        <p className="totalProducts">
          {t('totalProducts')}
          <span>{totalItemsCount}</span>
        </p>
        <Pagination
          hideFirstLastPages
          activePage={filters.page}
          itemsCountPerPage={paginationLimit}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={3}
          onChange={(e) => dispatch(setPage(e))}
          activeClass="pagination-active"
          innerClass="pagination-nav"
          prevPageText={<FaCaretLeft />}
          nextPageText={<FaCaretRight />}
        />
      </PaginationContainer>
      <ContextLoading isLoading={!ready}>
        <Loading>
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            animationInDelay={300}
            isVisible
          >
            <Container>
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationOutDuration={300}
                isVisible={infoBar}
              >
                <ApproveAllDialog
                  handleApproveAll={handleApproveAll}
                  showDialog={showApproveAllDialog}
                  isVisible={infoBar}
                />
              </Animated>
              <ResponsiveContainer className="responsive-container">
                {products &&
                  products.map((product) => (
                    <ReviewItem
                      key={product._id}
                      overlayEffect={setOverlayEffect(product.status)}
                      animationIn="fadeIn"
                      animationOut={
                        product.status === PRODUCT_STATUS.APPROVED
                          ? 'bounceOutRight'
                          : 'bounceOutLeft'
                      }
                      header={{
                        title: product.name,
                        updated: product.lastStatusUpdate,
                      }}
                      product={product}
                      activeClass={activeItem === product._id ? 'active' : ''}
                      activeItem={setActiveItem}
                    >
                      <ReviewActions>
                        <button
                          type="button"
                          onClick={() => handleRejectProductDialog(product._id)}
                          className="btn btn-reject"
                        >
                          {t('reviewActions.reject')}
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            dispatch(
                              updateProductStatusRequest({
                                productId: product._id,
                                status: PRODUCT_STATUS.RUNNING,
                              })
                            )
                          }
                          className="btn btn-approve"
                        >
                          {t('reviewActions.approve')}
                        </button>
                      </ReviewActions>
                    </ReviewItem>
                  ))}
              </ResponsiveContainer>
              {!products ||
                (emptyReview && (
                  <Animated
                    animationIn="fadeIn"
                    animationOut="fadeOut"
                    animationInDelay={products.length ? 1500 : 0}
                    isVisible={emptyReview}
                  >
                    <PendingReviewsEmpty>
                      <div>
                        <h4>
                          <Trans
                            i18nKey={
                              userType !== 'CLIENT'
                                ? 'pendingReviews/index:reviewsEmpty.infoAdmin'
                                : 'pendingReviews/index:reviewsEmpty.info'
                            }
                          >
                            <strong />
                          </Trans>
                        </h4>
                        <img src={wellDone} alt={t('reviewsEmpty.imgAlt')} />

                        {userType !== 'CLIENT' ? null : (
                          <Link to="/models" className="btn btn-default">
                            {t('reviewsEmpty.toModelsBtn')}
                          </Link>
                        )}
                      </div>
                    </PendingReviewsEmpty>
                  </Animated>
                ))}
            </Container>
          </Animated>
        </Loading>
      </ContextLoading>
    </>
  )
}
