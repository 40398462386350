import { Product } from '../product/types'

export enum ModelTypes {
  'LOAD_MODELS_REQUEST' = '@models/LOAD_MODELS_REQUEST',
  'CHANGE_STATUS' = '@models/CHANGE_STATUS',
  'LOAD_MODELS_SUCCESS' = '@models/LOAD_MODELS_SUCCESS',
  'LOAD_BASE_MODELS_SUCCESS' = '@models/LOAD_BASE_MODELS_SUCCESS',
  'SEARCH_MODELS_REQUEST' = '@models/SEARCH_MODELS_REQUEST',
  'UPDATE_MULTIPLE_MODEL_STATUS_REQUEST' = '@models/UPDATE_MULTIPLE_MODEL_STATUS_REQUEST',
  'TOGGLE_PRODUCT_RUNNING_OR_PAUSED_REQUEST' = '@models/TOGGLE_PRODUCT_RUNNING_OR_PAUSED_REQUEST',
  'DELETE_PRODUCT_REQUEST' = '@models/DELETE_PRODUCT_REQUEST',
  'PROCESS_FILTER_TOGGLE' = '@models/PROCESS_FILTER_TOGGLE',
  'REFRESH_IN_MEMORY_DELETED_ITENS_REQUEST' = '@models/REFRESH_IN_MEMORY_DELETED_ITENS_REQUEST',
}

export interface ModelsState {
  models: Product[]
  totalItemsCount: number
  baseModels?: Product[]
  processingFilter?: boolean
}
