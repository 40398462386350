import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import 'react-image-gallery/styles/css/image-gallery.css'

import { FaDownload, FaTimes, FaExpand } from 'react-icons/fa'
import ImageGallery from 'react-image-gallery'

import { Container } from './styles'

interface Props {
  images: Array<{ original: string; originalAlt: string }>
}

export default function Carouselefault({ images }: Props) {
  const { t } = useTranslation(['components/carousel/index'])

  const [index, setIndex] = useState(0)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function renderCustomControls() {
    return (
      <>
        <div className="gallery-custom-header">
          <a
            // eslint-disable-next-line react/prop-types
            href={images[index].original}
            className="image-gallery-icon image-gallery-download-icon"
            download
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('downloadImage')}
            <FaDownload />
          </a>
        </div>
      </>
    )
  }

  const renderFullscreenButton = (
    onClick: React.MouseEventHandler<HTMLElement>,
    isFullscreen: boolean
  ) => (
    <button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button"
      onClick={onClick}
      aria-label={t('ariaOpenFullScreen')}
    >
      {isFullscreen ? <FaTimes /> : <FaExpand />}
    </button>
  )

  return images.length > 0 ? (
    <Container>
      <ImageGallery
        showPlayButton={false}
        showThumbnails={false}
        useBrowserFullscreen={false}
        items={images}
        renderFullscreenButton={renderFullscreenButton}
        // renderCustomControls={renderCustomControls}
        additionalClass="custom-layout"
        onSlide={setIndex}
      />
    </Container>
  ) : (
    <div />
  )
}
