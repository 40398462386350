import React, { useEffect } from 'react'
import { Line } from 'rc-progress'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Container } from './styles'
import { UploadFile } from '../../../../store/modules/modal/types'
import {
  removeProductFile,
  updateProductFile,
} from '../../../../store/modules/modal/actions'
import upload from './upload'

interface Props {
  uploadFile: UploadFile
}

const FileInfo: React.FC<Props> = ({ uploadFile }: Props) => {
  const size = `${(uploadFile.file.size / (1024 * 1024)).toFixed(2)} MB`

  const fileName = uploadFile.file.name
  const fileType = uploadFile.file.name.split('.').pop()

  const dispatch = useDispatch()

  const onProgress = ({ loaded, total, url }) => {
    const percent = (100 * loaded) / total
    dispatch(updateProductFile({ file: uploadFile.file, percent, url }))
  }

  const onError = () => {
    dispatch(removeProductFile(uploadFile))
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    upload({ file: uploadFile.file, onProgress, onError })
  }, [])
  //  Lint desabilitado para ter o comportamento de componentDidMount
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <Container>
      <p>{fileType.toUpperCase()}</p>
      <p className="file-name">{fileName}</p>
      <p>{size}</p>
      <div>
        <Line
          percent={uploadFile.percent}
          strokeColor={uploadFile.percent === 100 ? '#6bb36b' : '#1f2f3b'}
          className="progress-bar"
        />
        <FaTimes
          size={10}
          className="unset-file-icon"
          onClick={() => dispatch(removeProductFile(uploadFile))}
        />
      </div>
    </Container>
  )
}

export default FileInfo
