import styled from 'styled-components/macro'
import { Row } from 'react-flexbox-grid'
import { maxSm, maxMd, maxXs } from '../../../styles/breakPoints'

export const ModelItemStyled = styled(Row)`
  padding: 10px 0;
  font-size: 22px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);

  strong.product {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 260px;
    width: 40%;
  }

  div.prod-info,
  div.title,
  div.prod-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div.item-info {
    width: 100%;
    display: flex;
  }

  div.title {
    height: 70px;

    img {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      margin: 0 20px;
    }
  }

  div.prod-options {
    width: 100%;
    margin-left: auto;

    small {
      font-size: 12px;
      font-weight: 300;
      margin-left: 40px;
    }

    .ar-control {
      display: flex;
      align-items: center;

      small {
        margin-right: 10px;
      }
    }

    span.icons {
      padding-right: 15px;
      font-size: 16px;

      button:active {
        opacity: 0.6;
        transform: scale(1.1);
        transition: 0.3s;
      }

      svg {
        margin: 8px 30px 0 0;
      }
    }
  }

  div.prod-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: 0 auto;

    p {
      width: 50%;
      max-width: 315px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 300;
    }

    p.text-missing {
      font-weight: 500;
    }

    svg {
      margin: 0 0 -2px 6px;
    }
  }

  @media (${maxMd}) {
    div.item-info {
      flex-direction: column;

      strong.product {
        font-size: 20px;
        max-width: 400px;
        width: 80%;
      }

      div.prod-info {
        margin: 0;
        font-size: 18px;
        margin: 5px 0 0;
      }
    }
  }

  @media (${maxSm}) {
    div.title img {
      margin: 0 10px 0 0;
    }

    div.item-info {
      flex-direction: column;

      strong.product {
        font-size: 17px;
        max-width: 220px;
      }

      div.prod-options {
        small {
          margin-left: 0;
        }

        div.ar-control {
          display: none;
        }

        span.icons {
          display: none;
        }
      }

      div.prod-info {
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        font-size: 14px;

        p {
          width: 100%;
        }
      }
    }
  }

  @media (${maxXs}) {
    div.title img {
      width: 50px;
    }

    div.item-info {
      strong.product {
        font-size: 15px;
        max-width: 180px;
        width: 70%;
      }
    }
  }

  @media (max-width: 340px) {
    div.item-info {
      strong.product {
        max-width: 120px;
      }
    }
  }
`
