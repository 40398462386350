import React from 'react'

interface Props {
  image: string
  textInfo: string
  className?: string
}

export default function InformationItem({ image, textInfo, className }: Props) {
  return (
    <div className="img-container">
      <img src={image} alt={textInfo} />
      <p className={className ?? ''}>{textInfo}</p>
    </div>
  )
}
