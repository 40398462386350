import { all, select, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import history from '../../../services/history'

// eslint-disable-next-line import/no-cycle
import api from '../../../services/api'

import { loadSessionSuccess, loadSessionFailure, setLoggedIn } from './actions'
import { AuthTypes } from './types'

import '../../../services/heap'

async function fetchApi(path, data, method) {
  return api[method](path, data).catch(() => ({ data: false }))
}

export function* session() {
  const { auth0Id, email, accessToken } = yield select(
    (state) => state.auth.auth0Data
  )

  const { data: user } = yield fetchApi('/session', { auth0Id, email }, 'post')

  if (!user) {
    yield put(setLoggedIn(false))
    yield put(loadSessionFailure())
    history.push('/logout')
    return
  }

  api.defaults.headers = {
    Authorization: `Bearer ${accessToken}`,
  }
  const {
    _id: id,
    type,
    name = '',
    customerId: { _id: customerId },
    termsAndConditionsUrl,
    termsAndConditionsId,
  } = user

  yield put(setLoggedIn(true))
  yield put(
    loadSessionSuccess({
      id,
      type,
      name,
      email,
      customerId,
      termsAndConditionsUrl,
      termsAndConditionsId,
      acceptTermsAndConditionsLoading: false,
    })
  )

  window.heap.identify(email)

  history.push('/')
}

export function* setApiToken({ payload }: AnyAction) {
  const data = payload.auth.auth0Data

  const { signed } = yield select((state) => state?.auth)

  if (signed && !data.idToken) {
    history.push('/logout')
  }

  if (signed && data.expiresAt * 1000 < new Date().getTime()) {
    history.push('/logout')
  }

  yield (api.defaults.headers = {
    Authorization: `Bearer ${data.idToken}`,
  })
}

function sessionExpired() {
  history.push('/logout')
}

export default all([
  takeLatest(AuthTypes.SESSION_REQUEST, session),
  takeLatest(AuthTypes.SESSION_EXPIRED, sessionExpired),
  takeLatest('persist/REHYDRATE', setApiToken),
])
