import styled from 'styled-components/macro'

import { maxXs, maxSm, maxXl } from '../../../styles/breakPoints'

interface Props {
  ready: boolean
}

export const Reasons = styled.div<Props>`
  min-height: 100px;

  div.reject-data {
    opacity: ${(props) => (props.ready ? 1 : 0)};
    display: flex;
    justify-content: space-between;
    margin: 42px auto 20px;
    width: 100%;
    border-radius: 10px;
    transition: 1s;

    div.reasons {
      width: 80%;

      label {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    textarea {
      min-height: 214px;
    }
  }

  button {
    margin: 50px 0px 0px auto;
  }

  @media (${maxXl}) {
    div.reject-data div.reasons {
      label {
        font-size: 18px;
      }
    }
  }

  @media (${maxSm}) {
    h2.title {
      text-align: center;
    }

    div.reject-data {
      flex-direction: column;
      width: 100%;
      margin: 20px auto;

      div.reasons {
        margin-bottom: 20px;

        label {
          margin: 0;
        }
      }
    }
  }

  @media (${maxXs}) {
    div.reject-data {
      div.reasons {
        label {
          font-size: 20px;
        }
      }
      textarea {
        min-height: 150px;
        font-size: 18px;
      }
    }
  }
`
