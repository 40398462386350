import { all, call, put, takeLatest } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import { ModalTypes } from './types'
import { createAsset, createProductSuccess } from './actions'
import { AssetsTypes } from '../product/types'
import i18n from '../../../config/i18n'

// eslint-disable-next-line import/no-cycle
import api from '../../../services/api'

export function* postProduct({ payload: data }: AnyAction) {
  const { name, sku, urls, customerId } = data
  const shouldTrigger3DOpsWorkflow = true
  try {
    toast.info(i18n.t('sagas:createProduct.onCreateProduct'))
    const response = yield call(api.post, 'products', {
      customerId,
      name,
      sku,
      serviceType: 'CGI',
      shouldTrigger3DOpsWorkflow,
    })

    const { _id: productId } = response.data

    try {
      if (urls.length) toast.info(i18n.t('sagas:createProduct.onCreateAssets'))
      yield all(
        urls.map((url) =>
          put(
            createAsset({
              productId,
              url,
              type: AssetsTypes.SKP,
              shouldTrigger3DOpsWorkflow,
            })
          )
        )
      )
    } catch (error) {
      toast.error(i18n.t('sagas:createProduct.createAssetsError'))
    }

    yield put(createProductSuccess())
    toast.success(i18n.t('sagas:createProduct.createProductSuccess'))
  } catch (response) {
    toast.error(i18n.t('sagas:createProduct.createProductError'))
  }
}

export function* postAsset({ payload: data }: AnyAction) {
  yield call(api.post, 'assets', data)
}

export default all([
  takeLatest(ModalTypes.CREATE_PRODUCT_REQUEST, postProduct),
  takeLatest(ModalTypes.CREATE_ASSETS, postAsset),
])
