import React, { ReactNode, useState, useMemo, createRef } from 'react'

import { useTranslation } from 'react-i18next'

import { Animated } from 'react-animated-css'

import { mobile } from 'is_js'

import { FaExternalLinkAlt, FaDownload } from 'react-icons/fa'
import { ModelViewer, ARLink } from '@r2u/react-ar-components'

import 'react-toggle/style.css'

import QRCode from 'qrcode.react'

import { RiCloseCircleFill } from 'react-icons/ri'

import { useSelector } from 'react-redux'
import googleArIcon from '../../../assets/images/icons/googleAr.svg'
import arWhiteIcon from '../../../assets/images/icons/ar-white/ar-white@2x.png'

import Carousel from '../../Carousel'

import ContextLoading from '../../Loading/ContextLoading'

import { Product } from '../../../store/modules/product/types'

import PendingServiceInfo from './PendingServiceInfo'

import Overlay from './Overlay'

import ToggleStatus from '../ToggleStatus'

import {
  ReviewItem,
  ReviewTitle,
  ProductInfo,
  ReviewInfo,
  CustomReviewActions,
  ImagesArea,
} from './styles'
import { ApplicationState } from '../../../store'

interface Header {
  title: string
  updated: string
}

interface ActionsProps {
  children: ReactNode
}

type Animations = 'fadeIn' | 'bounceInLeft' | 'bounceOutRight' | 'bounceOutLeft'

interface Props {
  header?: Header
  product: Product
  children?: ReactNode
  animationIn?: Animations
  animationOut?: Animations
  overlayEffect: string
  activeItem?: (active: string) => void
  activeClass?: string
  handleToggleRunningOrPausedProduct?: (
    status: string,
    productId: string
  ) => void
  toggleAREnable?: boolean
}

enum Items {
  References,
  Information,
  Renders,
  Model,
}

enum Containers {
  ProductInformation,
  ProductReview,
}

export default function ({
  header,
  product,
  children,
  overlayEffect,
  animationIn,
  animationOut,
  activeItem,
  activeClass,
  handleToggleRunningOrPausedProduct,
  toggleAREnable = false,
}: Props) {
  const { t, ready } = useTranslation([
    'components/products/reviewItem/index',
    'models/modelItem',
  ])

  const [itemsVisible, setItemsVisible] = useState({
    containers: ['show-md', 'hide-md'],
    childrens: ['show-sm', 'hide-sm', 'hide-sm', 'hide-sm'],
    activeIndex: 0,
  })

  const [openQRCode, setOpenQRCode] = useState(false)

  const classNames = `${header && 'shadow'} ${!product.visible && 'hide-'}`

  const [productImage] = product.referenceImages.length
    ? product.referenceImages
    : product.renderImages

  function handleItemVisible(index: number, container = 0) {
    setItemsVisible({
      containers: itemsVisible.containers.map(([,], count) =>
        count === container ? 'show-md' : 'hide-md'
      ),
      childrens: itemsVisible.childrens.map(([,], count) =>
        count === index ? 'show-sm' : 'hide-sm'
      ),
      activeIndex: container,
    })
  }

  const productHasRenders = useMemo(() => {
    return product.renderImages.length
  }, [product])

  const productHasModel = useMemo(() => {
    return product.glb && product.usdz
  }, [product])

  const locationUrl = `https://viewer.r2u.io/?customerId=${product.customerId}&sku=${product.sku}`

  const { type: userType } = useSelector(
    (state: ApplicationState) => state.user
  )

  return (
    <ContextLoading isLoading={!ready}>
      <Animated
        animationIn={animationIn}
        animationOut={animationOut}
        animationOutDelay={600}
        isVisible={product.visible}
        className={activeClass}
      >
        <ReviewItem
          className={classNames}
          onClick={() => (activeItem ? activeItem(product._id) : null)}
        >
          <Overlay effect={overlayEffect} />

          <div className="controls">
            <button
              className={[
                itemsVisible.activeIndex === Containers.ProductInformation &&
                  'active',
                'show-md',
              ].join(' ')}
              type="button"
              onClick={() =>
                handleItemVisible(
                  Items.References,
                  Containers.ProductInformation
                )
              }
            >
              {t('responsiveTabControls.informations')}
            </button>
            <button
              className={[
                itemsVisible.activeIndex === Containers.ProductReview &&
                  'active',
                'show-md',
              ].join(' ')}
              type="button"
              onClick={() =>
                handleItemVisible(
                  productHasRenders ? Items.Renders : Items.Model,
                  Containers.ProductReview
                )
              }
            >
              {t('responsiveTabControls.images3dAndModels')}
            </button>
          </div>

          {header && (
            <ReviewTitle md={12} className="header">
              <img
                src={productImage || '/no-image-available.png'}
                alt={t('productImageAlt')}
              />
              <h4>{header.title}</h4>
              <small>
                <span>{t('lastUpdated')}</span>
                {header.updated}
              </small>
            </ReviewTitle>
          )}

          <ProductInfo
            sm={12}
            md={12}
            lg={6}
            className={[
              itemsVisible.containers[Containers.ProductInformation],
              'product-info',
            ].join(' ')}
          >
            <h4 className="title">{t('title.productInformation')}</h4>
            <div className="content">
              <ImagesArea className={itemsVisible.childrens[Items.References]}>
                {product.referenceImages.length ? (
                  <Carousel
                    images={product.referenceImages.map((url) => ({
                      original: url,
                      originalAlt: t('productImageAlt'),
                    }))}
                  />
                ) : (
                  <PendingServiceInfo
                    infoType="reference"
                    serviceType={product.serviceType}
                    productStatus={product.status}
                  />
                )}
              </ImagesArea>

              <div className="info">
                <div className="info-item product-name-sm">
                  <strong>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={product.url}
                    >
                      <span>{product.name}</span>
                      {product.url && <FaExternalLinkAlt />}
                    </a>
                  </strong>
                </div>
                <div className="info-item">
                  {/* eslint-disable-next-line */}
                  <p>SKU </p>
                  <strong>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={product.url}
                    >
                      <span>{product.sku}</span>
                      {product.url && <FaExternalLinkAlt />}
                    </a>
                  </strong>
                </div>
                {!header && (
                  <div className="info-item">
                    {/* eslint-disable-next-line */}
                    <p>Status</p>
                    <strong>
                      {t(`models/modelItem:productStatus.${product.status}`)}
                    </strong>
                  </div>
                )}
                <div className="info-item">
                  <p>{t('productInformation.height')}</p>
                  <strong className="dimensions">
                    {product.height ?? 'N/A'}
                  </strong>
                </div>
                <div className="info-item">
                  <p>{t('productInformation.width')}</p>
                  <strong className="dimensions">
                    {product.width ?? 'N/A'}
                  </strong>
                </div>
                <div className="info-item">
                  <p>{t('productInformation.depth')}</p>
                  <strong className="dimensions">
                    {product.depth ?? 'N/A'}
                  </strong>
                </div>
                {toggleAREnable ? (
                  <div className="info-item ar-control">
                    <p>{t('productInformation.activateAR')}</p>
                    <strong>
                      <ToggleStatus
                        productStatus={product.status}
                        onChange={(status) =>
                          handleToggleRunningOrPausedProduct(
                            status,
                            product._id
                          )
                        }
                      />
                    </strong>
                  </div>
                ) : null}
              </div>
            </div>
          </ProductInfo>

          <ReviewInfo
            sm={12}
            lg={6}
            className={itemsVisible.containers[Containers.ProductReview]}
          >
            <h4 className="title">{t('title.reviewImagesAndModels')}</h4>
            <div className="images">
              <ImagesArea className={itemsVisible.childrens[Items.Renders]}>
                {product.renderImages.length ? (
                  <div className="render-box">
                    {productHasModel && (
                      <>
                        <button
                          type="button"
                          className="action-button button-3d"
                          onClick={() =>
                            handleItemVisible(
                              Items.Model,
                              Containers.ProductReview
                            )
                          }
                        >
                          <img
                            src={arWhiteIcon}
                            alt="AR icon"
                            className="active"
                          />
                        </button>
                      </>
                    )}
                    <Carousel
                      images={product.renderImages.map((url) => ({
                        original: url,
                        originalAlt: t('productImageAlt'),
                      }))}
                    />
                  </div>
                ) : (
                  <div className="render-box">
                    <PendingServiceInfo
                      infoType="render"
                      serviceType={product.serviceType}
                      productStatus={product.status}
                    />
                  </div>
                )}
              </ImagesArea>

              <div
                className={[
                  itemsVisible.childrens[Items.Model],
                  'model-viewer',
                ].join(' ')}
              >
                {product.glb ? (
                  <div className="viewer-box">
                    {!!productHasRenders && (
                      <button
                        type="button"
                        className="action-button button-3d active"
                        onClick={() => {
                          handleItemVisible(
                            Items.Renders,
                            Containers.ProductReview
                          )
                        }}
                      >
                        <img
                          src={arWhiteIcon}
                          alt="AR icon"
                          className="active"
                        />
                      </button>
                    )}
                    <div>
                      <ModelViewer
                        shadowSoftness="1"
                        popup
                        autoRotate={false}
                        glb={product.glb}
                        reveal="interaction"
                      >
                        {userType !== 'CLIENT' ? (
                          <a
                            href={locationUrl.toString()}
                            className="link-viewer"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div>
                              <FaExternalLinkAlt />
                            </div>
                          </a>
                        ) : null}
                        <img
                          alt="thumbnail"
                          className="thumbnailUrl"
                          slot="poster"
                          src={product.thumbnailUrl}
                        />
                        <div className="clickPoster" slot="poster">
                          <FaDownload size={15} className="iconDownload" />
                          <span>{t('load3dModel')}</span>
                        </div>
                      </ModelViewer>
                    </div>

                    <button
                      type="button"
                      className="action-button button-ar"
                      onClick={() => {
                        setOpenQRCode(!openQRCode)
                      }}
                    >
                      {mobile() ? (
                        <ARLink
                          glb={product.glb}
                          usdz={product.usdz}
                          linkRef={createRef()}
                          fallbackOptions={{
                            alertMessage: 'AR not available on device',
                          }}
                        >
                          <img
                            className="ar-icon"
                            src={googleArIcon}
                            alt="Ar Link"
                          />
                        </ARLink>
                      ) : (
                        <div className="qrcode">
                          <div>
                            <img
                              className="ar-icon"
                              src={googleArIcon}
                              alt="Ar Link"
                            />
                          </div>
                          {openQRCode ? (
                            <div className="card-qrcode">
                              <RiCloseCircleFill
                                size={30}
                                cursor="pointer"
                                className="close-icon"
                                style={{
                                  right: '0',
                                  position: 'absolute',
                                  marginTop: '-23px',
                                }}
                                onClick={() => setOpenQRCode(!openQRCode)}
                              />
                              <span
                                style={{
                                  color: '#000',
                                  fontSize: '12px',
                                  fontFamily: 'futura-pt',
                                }}
                              >
                                {t('qrcode')}
                              </span>
                              <QRCode
                                value={locationUrl.toString()}
                                style={{ marginTop: '10px' }}
                                renderAs="svg"
                              />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="viewer-box">
                    <PendingServiceInfo
                      infoType="model"
                      serviceType={product.serviceType}
                      productStatus={product.status}
                    />
                  </div>
                )}
              </div>
            </div>
          </ReviewInfo>
          {children}
        </ReviewItem>
      </Animated>
    </ContextLoading>
  )
}

export const ReviewActions = ({ children }: ActionsProps) => (
  <CustomReviewActions>{children}</CustomReviewActions>
)
