import { Reducer } from 'redux'
import { PendingReviewState, PendingReviewsTypes } from './types'

const INITIAL_STATE: PendingReviewState = {
  products: [],
  totalItemsCount: 0,
  loaded: false,
  infoBar: false,
  isUpdating: false,
}

const reducer: Reducer<PendingReviewState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case PendingReviewsTypes.LOAD_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.products,
        loaded: true,
      }
    case PendingReviewsTypes.SET_TOTAL_ITEMS:
      return {
        ...state,
        totalItemsCount: action.payload.totalItems,
      }
    case PendingReviewsTypes.CHANGE_INFO_BAR_VISIBILITY:
      return { ...state, infoBar: action.payload.isVisible }

    case PendingReviewsTypes.SET_IS_UPDATING:
      return { ...state, isUpdating: action.payload.isUpdating }
    default:
      return state
  }
}

export default reducer
