import config from '../config/application.json'

export const AUTH_CONFIG = {
  domain: config[process.env.REACT_APP_STAGE].AUTH0_DOMAIN,
  clientId: config[process.env.REACT_APP_STAGE].AUTH0_CLIENT_ID,
  audience: config[process.env.REACT_APP_STAGE].AUTH0_AUDIENCE,
  callbackUrl: config[process.env.REACT_APP_STAGE].AUTH0_CALLBACK_URL,
  container: 'auth0-login-container',
  allowSignUpKey: 'a7f5',
}
