import styled from 'styled-components/macro'

import { maxXs } from '../../../styles/breakPoints'

export const ModalContent = styled.div`
  position: relative;
  background-color: #fefefe;
  margin: 12vh auto 0 auto;
  padding: 35px;
  border: 1px solid #888;
  max-width: 720px;
  border: solid 1px #707070;
  border-radius: 10px;

  @media (${maxXs}) {
    padding: 25px;
  }
`

export const ModalBody = styled.div`
  margin: 0 auto;
  min-height: 300px;
  border-radius: 10px;
  background-color: #ffffff;
`

export const ModalFooter = styled.div`
  button.align-left {
    margin-left: auto;
  }

  button.align-right {
    margin-right: auto;
  }
`
