import styled, { keyframes } from 'styled-components/macro'

import { maxSm, maxXs } from '../../../styles/breakPoints'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 12% auto 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 780px;
  text-align: center;
  border: solid 1px #707070;

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
  }

  @media (${maxSm}) {
    padding: 10px;
    width: 100%;
  }
`

export const ModalBody = styled.div`
  position: relative;
  margin: 20px auto;
  width: 100%;
  max-width: 650px;
  height: 356px;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  overflow: hidden scroll;

  svg,
  .react-pdf__Page__svg {
    width: 100% !important;
    height: auto !important;
  }

  svg {
    transform: scale(1.2);
  }

  div {
    color: #707070;
    font-size: 14px;
    line-height: 18px;
  }

  &.fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    min-height: 100vh;
    z-index: 99;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (${maxXs}) {
    max-height: 256px;

    svg {
      transform: scale(1.25);
    }
  }
`

export const ModalFooter = styled.div`
  position: relative;
  margin-top: 30px;

  button.btn-fullscreen {
    display: none;
    position: absolute;
    background: none;
    padding: 12px;
    border: none;
    top: -72px;
    right: 5px;
    opacity: 0.7;
    z-index: 999;

    &:active {
      opacity: 1;
      transform: translateY(1px);
    }

    &.btn-fullscreen-active {
      position: fixed;
      top: unset;
      bottom: 35px;
      z-index: 999;
    }

    svg {
      color: #1f2f3b;
      font-size: 20px;
    }
  }

  button.accept-terms {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 40px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #1f2f3b;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    text-align: center;

    svg {
      animation: ${rotate} 2s linear infinite;
      margin-left: 10px;
    }

    &:disabled {
      background-color: #ccc;
    }
  }

  @media (${maxXs}) {
    button.btn-fullscreen {
      display: block;
    }
  }
`
