import { action } from 'typesafe-actions'
import {
  FileUpdate,
  ModalTypes,
  NewAsset,
  NewProduct,
  UploadFile,
} from './types'

export const showModal = (show: boolean) =>
  action(ModalTypes.SHOW_MODAL, { show })
export const createProductRequest = (payload: NewProduct) =>
  action(ModalTypes.CREATE_PRODUCT_REQUEST, payload)
export const createProductSuccess = () =>
  action(ModalTypes.CREATE_PRODUCT_SUCCESS)
export const createAsset = (payload: NewAsset) =>
  action(ModalTypes.CREATE_ASSETS, payload)
export const setProductName = (name: string) =>
  action(ModalTypes.SET_NAME, { name })
export const setProductSku = (sku: string) =>
  action(ModalTypes.SET_SKU, { sku })
export const addProductFile = (file: File) =>
  action(ModalTypes.ADD_FILE, { file })
export const removeProductFile = (uploadFile: UploadFile) =>
  action(ModalTypes.REMOVE_FILE, { uploadFile })
export const updateProductFile = (payload: FileUpdate) =>
  action(ModalTypes.UPDATE_FILE, payload)
