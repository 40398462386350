import React from 'react'

import { useTranslation } from 'react-i18next'

import { Col } from 'react-flexbox-grid'
import { FaDownload, FaTrash } from 'react-icons/fa'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

import { Product, PRODUCT_STATUS } from '../../../store/modules/product/types'

import { ModelItemStyled } from './styles'

import ToggleStatus from '../../products/ToggleStatus'

interface Props {
  product: Product
  expandedItem: string
  handleToggleRunningOrPausedProduct: (
    status: string,
    productId: string
  ) => void
  handleDeleteProduct: (_id: string) => void
  handleDownloadProduct: (
    usdz: string,
    glb: string,
    renderImages: string[],
    productName: string
  ) => void
}

export default function ModelItem({
  product,
  expandedItem,
  handleToggleRunningOrPausedProduct,
  handleDeleteProduct,
  handleDownloadProduct,
}: Props) {
  const { t, ready } = useTranslation(['models/modelItem'])

  const [prodImg] = product.referenceImages.length
    ? product.referenceImages
    : product.renderImages

  return (
    <ModelItemStyled
      className={['model-item', product.deleted && 'product-deleted'].join(' ')}
    >
      <Col sm={12} className="title">
        <img src={prodImg || '/no-image-available.png'} alt="" />
        <div className="item-info">
          <strong className="product">{product.name}</strong>
          {product._id === expandedItem ? (
            <div className="prod-options">
              <small>
                {t('lastUpdated')}
                {product.lastStatusUpdate}
              </small>
              <div className="ar-control">
                <small>{t('activateAR')}</small>
                <ToggleStatus
                  productStatus={product.status}
                  onChange={(status) =>
                    handleToggleRunningOrPausedProduct(status, product._id)
                  }
                />
              </div>
              <span className="icons">
                <button
                  type="button"
                  className="transparent"
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDeleteProduct(product._id)
                  }}
                >
                  <FaTrash />
                </button>
                <button
                  type="button"
                  className="transparent"
                  onClick={() =>
                    handleDownloadProduct(
                      product.usdz,
                      product.glb,
                      product.renderImages,
                      product.name
                    )
                  }
                >
                  <FaDownload />
                </button>
              </span>
            </div>
          ) : (
            <div className="prod-info">
              <p>
                {/* eslint-disable-next-line react/jsx-no-literals */}
                {product.sku}
              </p>

              <p
                className={
                  product.status === PRODUCT_STATUS.MISSING_INFORMATION
                    ? 'text-missing'
                    : ''
                }
              >
                {
                  product.status !== PRODUCT_STATUS.MISSING_INFORMATION
                  // eslint-disable-next-line react/jsx-no-literals
                }

                {ready ? (
                  <span>
                    {t(`productStatus.${product.status}`)}
                    {product.status === PRODUCT_STATUS.MISSING_INFORMATION && (
                      <AiOutlineExclamationCircle size={16} />
                    )}
                  </span>
                ) : (
                  '...'
                )}
              </p>
            </div>
          )}
        </div>
      </Col>
    </ModelItemStyled>
  )
}
