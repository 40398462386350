import styled from 'styled-components/macro'
import { maxSm } from '../../styles/breakPoints'

import background from '../../assets/images/bg/bg-dashboard.png'

interface Props {
  hasHeader: boolean
}

export const BgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props: Props) =>
    props.hasHeader ? 'calc(100vh - 70px)' : '100vh'};
  height: ${(props: Props) =>
    props.hasHeader
      ? 'calc((var(--vh, 1vh) * 100) - 70px)'
      : 'calc((var(--vh, 1vh) * 100))'};
  width: 100%;
  background: url(${background}) no-repeat center;
  background-size: cover;

  &::before {
    content: '';
    position: absolute;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(214, 214, 214, 0),
      rgba(53, 67, 77, 0.69) 44%,
      rgba(31, 47, 59, 0.79)
    );
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
  }

  @media (${maxSm}) {
    height: ${(props: Props) =>
      props.hasHeader
        ? 'calc((var(--vh, 1vh) * 100) - 70px)'
        : 'calc((var(--vh, 1vh) * 100) + 2px)'};
  }
`

export const CenterBox = styled.div`
  width: 60%;
  color: #fff;
  z-index: 5;

  h1 {
    font-size: 45px;
    line-height: 1.29;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 18px;
    font-weight: 300;
    margin: 10px 0px 18px 0px;
  }

  a {
    display: inline-block;
    text-decoration: none;
    width: 172px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-right: 30px;
    border: none;
    border-radius: 5px;
    transition: 0.2s;
  }

  a.btn-default {
    border-radius: 10px;
    border: solid 1px #ffffff;
    background-color: #ffffff;
    margin-bottom: 15px;
    color: #000;

    &:hover {
      background: #1f2f3b;
      border-color: #1f2f3b;
      color: #fff;
    }
  }

  a.btn-outline {
    border-radius: 10px;
    border: solid 1px #ffffff;
    background: transparent;
    color: #fff;

    &:hover {
      background: #fff;
      color: #000;
    }
  }

  @media (${maxSm}) {
    width: 85%;
  }
`
