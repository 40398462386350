import React, { ReactNode } from 'react'

import {
  Accordion as AccordionArea,
  AccordionItem as ItemAccordion,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import './styles.css'

interface AccordionProps {
  allowZeroExpanded?: boolean
  children: ReactNode
  onChange?: Function
}

export function Accordion({
  children,
  allowZeroExpanded,
  onChange,
}: AccordionProps) {
  return (
    <AccordionArea
      allowZeroExpanded={allowZeroExpanded}
      onChange={(data) => onChange(data)}
      className="accordion-area"
    >
      {children}
    </AccordionArea>
  )
}

interface AccordionItemProps {
  children: ReactNode
  uuid: string
}

export function AccordionItem({ children, uuid }: AccordionItemProps) {
  return <ItemAccordion uuid={uuid}>{children}</ItemAccordion>
}

interface AccordionHeaderContentProps {
  children: ReactNode
}
export function AccordionHeader({ children }: AccordionHeaderContentProps) {
  return (
    <AccordionItemHeading>
      <AccordionItemButton>{children}</AccordionItemButton>
    </AccordionItemHeading>
  )
}

export function AccordionContent({ children }: AccordionHeaderContentProps) {
  return <AccordionItemPanel>{children}</AccordionItemPanel>
}
