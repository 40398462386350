import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { FaTimes, FaCheck, FaChevronLeft } from 'react-icons/fa'
import { FiCheckSquare, FiSearch } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import ContextLoading from '../Loading/ContextLoading'
import { CreateProductModal } from '../Modal'

import {
  SubHeaderStyled,
  ProductsActions,
  Actions,
  ReviewActions,
  TextInput,
  Select,
} from './styles'

import { ApplicationState } from '../../store'
import { PRODUCT_STATUS } from '../../store/modules/product/types'
import { getCustomersRequest } from '../../store/modules/user/actions'
import { setNameOrSku, setStatus } from '../../store/modules/filters/actions'

import Checkbox from '../_html/Checkbox'
import CustomerFilter from '../Admin/CustomerFilter'
import { showModal } from '../../store/modules/modal/actions'

interface Props {
  handleAllCheck: (e: React.MouseEvent<HTMLInputElement>) => void
  handleShowCheckbuttonForItems: (status: boolean) => void
  handleApproveOrReproveAllDialog: (status: string, visibility: boolean) => void
  showCheckboxForItems: boolean
  disableCheckAll: boolean
  checkAllItems: boolean
  rejectMenu: boolean
  hasItemChecked: boolean
  messages: Record<string, string>
}

export default function SubHeader({
  disableCheckAll,
  checkAllItems,
  messages,
  handleAllCheck,
  rejectMenu,
  hasItemChecked,
  showCheckboxForItems,
  handleShowCheckbuttonForItems,
  handleApproveOrReproveAllDialog,
}: Props) {
  const { t, ready } = useTranslation([
    'models/index',
    'materials/index',
    'models/modelItem',
    'pendingReviews/subHeader',
  ])
  const [approveConfirmationOpen, setAproveConfirmationOpen] = useState(false)
  const [showMobileFilter, setShowMobileFilter] = useState(false)
  const [nameOrSkuInput, setNameOrSkuInput] = useState('')
  const customerLimitFilter = 1000
  const { status } = useSelector((state: ApplicationState) => state.filters)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCustomersRequest({ limit: customerLimitFilter }))
  }, [dispatch])

  useEffect(() => {
    handleShowCheckbuttonForItems(showCheckboxForItems)
    setAproveConfirmationOpen(false)
  }, [showCheckboxForItems, handleShowCheckbuttonForItems])

  useEffect(() => {
    if (!hasItemChecked) {
      handleApproveOrReproveAllDialog(PRODUCT_STATUS.APPROVED, false)
      setAproveConfirmationOpen(false)
    }
  }, [hasItemChecked, handleApproveOrReproveAllDialog])

  return (
    <ContextLoading isLoading={!ready}>
      <SubHeaderStyled mobileFilter={showMobileFilter}>
        <div className="container">
          <CustomerFilter />
        </div>
        <div className="container">
          <Actions xs={12} className="actions">
            <div className="responsive-sub-header">
              <div className="top-action">
                <span
                  className={[
                    'check-all',
                    !showCheckboxForItems && 'hide-content-xs',
                  ].join(' ')}
                >
                  <button
                    type="button"
                    className="check-all-back transparent"
                    onClick={() =>
                      handleShowCheckbuttonForItems(!showCheckboxForItems)
                    }
                  >
                    <FaChevronLeft />
                  </button>
                  <Checkbox
                    disabled={disableCheckAll}
                    onChange={handleAllCheck}
                    checked={checkAllItems}
                  />
                </span>
                <h3>{messages.title}</h3>
              </div>
              <ProductsActions
                className={[
                  'products-actions',
                  hasItemChecked && 'hide-content-min-sm',
                  showCheckboxForItems && 'has-item-checked-md',
                  showMobileFilter && 'mobile-filter',
                ].join(' ')}
              >
                <div className="filter">
                  <TextInput>
                    <input
                      placeholder={messages.search}
                      type="text"
                      onChange={(e) => setNameOrSkuInput(e.target.value)}
                      onKeyPress={(e) =>
                        e.charCode === 13 &&
                        dispatch(setNameOrSku(nameOrSkuInput))
                      }
                      value={nameOrSkuInput}
                    />
                    <button
                      type="button"
                      className="transparent"
                      onClick={() => dispatch(setNameOrSku(nameOrSkuInput))}
                    >
                      <FiSearch />
                    </button>
                  </TextInput>
                  <Select
                    onChange={(e) => {
                      dispatch(setStatus(e.target.value))
                    }}
                    value={status}
                  >
                    <option value="">{messages.statusFilter}</option>
                    <option value={PRODUCT_STATUS.APPROVED}>
                      {t('models/modelItem:productStatus.APPROVED')}
                    </option>
                    <option value={PRODUCT_STATUS.REJECTED}>
                      {t('models/modelItem:productStatus.REJECTED')}
                    </option>
                    <option value={PRODUCT_STATUS.MISSING_INFORMATION}>
                      {t('models/modelItem:productStatus.MISSING_INFORMATION')}
                    </option>
                    <option value={PRODUCT_STATUS.EXTERNAL_REVIEW}>
                      {t('models/modelItem:productStatus.EXTERNAL_REVIEW')}
                    </option>
                    <option value={PRODUCT_STATUS.READY_TO_START}>
                      {t('models/modelItem:productStatus.READY_TO_START')}
                    </option>
                    <option value={PRODUCT_STATUS.IN_PROGRESS}>
                      {t('models/modelItem:productStatus.IN_PROGRESS')}
                    </option>
                    <option value={PRODUCT_STATUS.RUNNING}>
                      {t('models/modelItem:productStatus.RUNNING')}
                    </option>
                    <option value={PRODUCT_STATUS.PAUSED}>
                      {t('models/modelItem:productStatus.PAUSED')}
                    </option>
                  </Select>
                </div>
                <button
                  type="button"
                  className="btn btn-default-outline add-product"
                  onClick={() => dispatch(showModal(true))}
                >
                  {messages.addProduct}
                </button>
                <CreateProductModal />
              </ProductsActions>
              <ReviewActions
                className={[
                  'review-actions',
                  !hasItemChecked && 'hide-content-min-sm',
                ].join(' ')}
              >
                <div
                  className={[
                    'controls-sm',
                    showCheckboxForItems && 'hide-content-xs',
                  ].join(' ')}
                >
                  <button
                    type="button"
                    className="check-btn"
                    onClick={() =>
                      handleShowCheckbuttonForItems(!showCheckboxForItems)
                    }
                  >
                    <FiCheckSquare />
                  </button>
                  <button
                    type="button"
                    className="check-btn"
                    onClick={() => setShowMobileFilter(!showMobileFilter)}
                  >
                    <FiSearch />
                  </button>
                </div>
                <div
                  className={[
                    'controls-default',
                    !showCheckboxForItems && 'hide-content-xs',
                  ].join(' ')}
                >
                  <div className="review-large review-small">
                    {approveConfirmationOpen ? (
                      <button
                        type="button"
                        className="btn btn-default-outline btn-cancel"
                        onClick={() => {
                          setAproveConfirmationOpen(false)
                          handleApproveOrReproveAllDialog(
                            PRODUCT_STATUS.REJECTED,
                            false
                          )
                          handleApproveOrReproveAllDialog(
                            PRODUCT_STATUS.APPROVED,
                            false
                          )
                        }}
                      >
                        {messages.cancel}
                      </button>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-reject action-review hide-content-min-sm"
                          onClick={() => {
                            handleApproveOrReproveAllDialog(
                              PRODUCT_STATUS.REJECTED,
                              true
                            )
                          }}
                          disabled={!hasItemChecked}
                        >
                          <FaTimes color="#d45b5b" />
                          <span>{messages.rejectButton}</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-reject action-review hide-content-xs"
                          onClick={() => {
                            setAproveConfirmationOpen(true)
                            handleApproveOrReproveAllDialog(
                              PRODUCT_STATUS.REJECTED,
                              true
                            )
                          }}
                          disabled={!hasItemChecked}
                        >
                          <FaTimes color="#d45b5b" />
                          <span>{t('rejectButton')}</span>
                        </button>
                        <button
                          type="button"
                          className={[
                            'btn btn-approve action-review',
                            rejectMenu && 'cs-hide',
                          ].join(' ')}
                          onClick={() => {
                            setAproveConfirmationOpen(true)
                            handleApproveOrReproveAllDialog(
                              PRODUCT_STATUS.APPROVED,
                              true
                            )
                          }}
                          disabled={!hasItemChecked}
                        >
                          <FaCheck color="#8ec396" />
                          <span>{messages.approveButton}</span>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </ReviewActions>
            </div>
          </Actions>
        </div>
      </SubHeaderStyled>
    </ContextLoading>
  )
}
