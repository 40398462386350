import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

import { FaSpinner, FaExpand } from 'react-icons/fa'
import PdfViewer from '../../PdfViewer'
import * as UserActions from '../../../store/modules/user/actions'
import { UserState } from '../../../store/modules/user/types'

import { ModalContainer } from '../styles'

import { ModalContent, ModalBody, ModalFooter } from './styles'

interface Props {
  url: string
}

interface State {
  user: UserState
}

export default function ({ url }: Props) {
  const dispatch = useDispatch()
  const { t, ready } = useTranslation([
    'components/modal/termsAndConditions/index',
  ])

  const [fullScreen, setFullScreen] = useState(false)

  const acceptTermsAndConditionsLoading = useSelector(
    (state: State) => state.user.acceptTermsAndConditionsLoading
  )

  const handleAccept = () => {
    dispatch(UserActions.acceptTermsRequest())
  }

  return ready ? (
    <ModalContainer visible>
      <ModalContent>
        <h3>{t('title')}</h3>
        <p>{t('subTitle')}</p>
        <ModalBody className={fullScreen && 'fullscreen'}>
          <PdfViewer fileName={url} loadingMessage={t('loadingMessage')} />
        </ModalBody>

        <ModalFooter>
          <button
            className={[
              'btn-fullscreen',
              fullScreen && 'btn-fullscreen-active',
            ].join(' ')}
            type="button"
            onClick={() => setFullScreen(!fullScreen)}
          >
            <FaExpand />
          </button>
          <button
            type="button"
            disabled={acceptTermsAndConditionsLoading}
            onClick={handleAccept}
            className="accept-terms"
          >
            {t('acceptAction')}
            {acceptTermsAndConditionsLoading && <FaSpinner />}
          </button>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  ) : (
    <div />
  )
}
