/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FaTimes } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { ModalContainer, CloseButton } from '../styles'

import {
  createProductRequest,
  removeProductFile,
  setProductName,
  setProductSku,
  showModal,
} from '../../../store/modules/modal/actions'
import { ApplicationState } from '../../../store'

import { ModalContent, ModalBody, ModalFooter, Container } from './styles'
import Dropzone from './Dropzone'
import FileInfo from './FileInfo'

export default function () {
  const dispatch = useDispatch()
  const { t } = useTranslation('components/modal/createProduct/index')

  const { show, name, sku, uploadFiles } = useSelector(
    (state: ApplicationState) => state.modal
  )
  const { customerId } = useSelector((state: ApplicationState) => state.user)

  const urls = uploadFiles.map((f) => f.url)

  const percents = uploadFiles.map((file) => file.percent)

  function resetFields() {
    dispatch(setProductName(''))
    dispatch(setProductSku(''))
    uploadFiles.map((uploadFile) => {
      return dispatch(removeProductFile(uploadFile))
    }, [])
    dispatch(showModal(false))
  }

  return (
    <ModalContainer visible={show}>
      <ModalContent>
        <CloseButton onClick={() => dispatch(showModal(false))}>
          <FaTimes size={20} />
        </CloseButton>
        <ModalBody>
          <h3>{t('newProduct')}</h3>
          <sub>{t('loadFileMessage')}</sub>
          <Container>
            <div className="left">
              <h4>{t('files')}</h4>
              <Dropzone />
              {uploadFiles.map((uploadFile) => {
                return (
                  <FileInfo
                    uploadFile={uploadFile}
                    key={uploadFile.file.name}
                  />
                )
              })}
            </div>
            <div className="right">
              <h4>{t('productDetails')}</h4>
              <div>
                <p>
                  <label>{t('name')}</label>
                  <input
                    type="text"
                    placeholder="Nome"
                    id="prod_name"
                    value={name}
                    onChange={(e) => dispatch(setProductName(e.target.value))}
                  />
                </p>
                <p>
                  <label>{t('sku')}</label>
                  <input
                    type="text"
                    placeholder="SKU"
                    id="prod_sku"
                    value={sku}
                    onChange={(e) => dispatch(setProductSku(e.target.value))}
                  />
                </p>
              </div>
            </div>
          </Container>
        </ModalBody>
        <ModalFooter>
          <span>
            <button
              type="button"
              onClick={() => resetFields()}
              className="btn-cancel"
            >
              <h4>{t('cancel')}</h4>
            </button>
            <button
              type="button"
              onClick={() => {
                dispatch(createProductRequest({ customerId, name, sku, urls }))
              }}
              disabled={
                !name || !sku || !percents.every((percent) => percent === 100)
              }
            >
              {t('send')}
            </button>
          </span>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
