import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import { store } from '../store'
import { sessionExpired } from '../store/modules/auth/actions'

import config from '../config/application.json'

const api = axios.create({
  baseURL: config[process.env.REACT_APP_STAGE].API_URL,
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(sessionExpired())
    }

    return Promise.reject(error.response)
  }
)

export default api
