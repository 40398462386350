import React from 'react'

import { useTranslation } from 'react-i18next'
import { ItemMissing } from './styles'

import {
  ServiceTypes,
  PRODUCT_STATUS,
} from '../../../store/modules/product/types'
import InformationItem from './InformationItem'

import missingReference from '../../../assets/images/missing-reference/missing-reference@2x.png'
import missingRender from '../../../assets/images/missing-render/missing-render@2x.png'
import missingModel from '../../../assets/images/missing-model/missing-model@2x.png'
import noPurchase from '../../../assets/images/no-purchase/no-purchase@2x.png'

interface Props {
  serviceType: string
  infoType: string
  productStatus: string
}

export default function PendingServiceInfo({
  serviceType,
  infoType,
  productStatus,
}: Props) {
  const { t } = useTranslation([
    'components/products/reviewItem/index',
    'models/modelItem',
  ])

  return (
    <ItemMissing>
      {infoType === 'model' && (
        <>
          {serviceType === ServiceTypes.BOTH ||
          serviceType === ServiceTypes.AUGMENTED_REALITY ? (
            <InformationItem
              image={missingModel}
              textInfo={
                productStatus === PRODUCT_STATUS.MISSING_INFORMATION ||
                productStatus === PRODUCT_STATUS.READY_TO_START
                  ? t('notStartedInformation.model')
                  : t('missingInformation.model')
              }
            />
          ) : (
            <InformationItem
              image={noPurchase}
              textInfo={t('noPurchaseInformation.model')}
            />
          )}
        </>
      )}

      {infoType === 'render' && (
        <>
          {serviceType === ServiceTypes.BOTH ||
          serviceType === ServiceTypes.CGI ? (
            <InformationItem
              image={missingRender}
              textInfo={
                productStatus === PRODUCT_STATUS.MISSING_INFORMATION ||
                productStatus === PRODUCT_STATUS.READY_TO_START
                  ? t('notStartedInformation.renders')
                  : t('missingInformation.renders')
              }
            />
          ) : (
            <InformationItem
              image={noPurchase}
              textInfo={t('noPurchaseInformation.renders')}
            />
          )}
        </>
      )}

      {infoType === 'reference' && (
        <InformationItem
          image={missingReference}
          textInfo={t('missingInformation.references')}
          className="text-missing"
        />
      )}
    </ItemMissing>
  )
}
