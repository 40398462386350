import React, { useCallback } from 'react'

import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { addProductFile } from '../../../../store/modules/modal/actions'
import { DropzoneContainer } from './styles'

const Dropzone: React.FC = () => {
  const accept = '.skp'

  const dispatch = useDispatch()

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      acceptedFiles.map((file) => dispatch(addProductFile(file)))
    },
    [dispatch]
  )

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept,
    noClick: true,
    noKeyboard: true,
  })

  const { t } = useTranslation('components/modal/createProduct/Dropzone/index')

  return (
    <div {...getRootProps({})}>
      <DropzoneContainer id="dropzoneContainer" isDragActive={isDragActive}>
        <input {...getInputProps()} />
        <p>{isDragActive ? t('dragMessage') : t('dropMessage')}</p>
        <button type="button" onClick={open}>
          {t('search')}
        </button>
      </DropzoneContainer>
    </div>
  )
}

export default Dropzone
