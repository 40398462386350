import {
  applyMiddleware,
  compose,
  createStore,
  Store,
  StoreEnhancer,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import { persistStore } from 'redux-persist'
import persistReducers from './persistReducers'

import rootReducer from './modules/rootReducer'
// eslint-disable-next-line import/no-cycle
import rootSaga from './modules/rootSaga'

import { AuthState } from './modules/auth/types'
import { UserState } from './modules/user/types'
import { PendingReviewState } from './modules/pendingReviews/types'
import { ModelsState } from './modules/models/types'
import { LoadingState } from './modules/loading/types'
import { FiltersState } from './modules/filters/types'
import { ModalState } from './modules/modal/types'

export interface ApplicationState {
  auth: AuthState
  user: UserState
  products: PendingReviewState
  models: ModelsState
  loading: LoadingState
  filters: FiltersState
  modal: ModalState
}

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null

const sagaMiddleware = createSagaMiddleware({ sagaMonitor })

const enhancer: StoreEnhancer =
  process.env.NODE_ENV === 'development'
    ? compose(console.tron.createEnhancer(), applyMiddleware(sagaMiddleware))
    : applyMiddleware(sagaMiddleware)

const store: Store = createStore(persistReducers(rootReducer), enhancer)

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export { persistor, store }
