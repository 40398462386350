import { createGlobalStyle } from 'styled-components/macro'

import { maxXs } from './breakPoints'

import 'react-toastify/dist/ReactToastify.css'
import './animate.min.css'

export default createGlobalStyle`
  @font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:500;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/1eb35a/000000000000000000010090/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/1eb35a/000000000000000000010090/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/1eb35a/000000000000000000010090/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:500;
}
@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/849347/000000000000000000010093/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/849347/000000000000000000010093/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/849347/000000000000000000010093/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:300;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:300;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/c4c302/000000000000000000012192/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:600;
}

@font-face {
font-family:"futura-pt";
src:url("https://use.typekit.net/af/1b297b/000000000000000000012193/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff2"),url("https://use.typekit.net/af/1b297b/000000000000000000012193/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("woff"),url("https://use.typekit.net/af/1b297b/000000000000000000012193/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i6&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:600;
}

@font-face {
font-family:"futura-pt-bold";
src:url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/053fc9/00000000000000003b9af1e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;
}  
  
  *, p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    outline: 0 !important;
    box-sizing: border-box;
  }
  
  html, body, #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, select {
    font: 14px 'futura-pt', helvetica, sans-serif;
  }

  #root, ul {
    margin: 0 auto;
    padding: 0;
  }

  .text-missing {
    color: #d45b5b;
  }

  .cs-hide {
    display: none !important;
  }

  /* forms */
  button {
    cursor: pointer;
  }

  *:focus, *:active {
    -webkit-tap-highlight-color: transparent !important;
    outline: none !important;
    -ms-touch-action: manipulation !important;
    touch-action: manipulation !important;
  }

  .btn {
    display: block;
    width: 140px;
    height: 40px;
    border-radius: 10px;
    line-height: 40px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-weight: 500;
    margin-left: 20px;
    cursor: pointer;
  }

  button:disabled {
    opacity: 0.4;
  }

  button.transparent {
    background: none;
    border: none;
  }

  .btn-default {
    border: solid 1px #1f2f3b;
    background-color: #1f2f3b;
  }

  .btn-default-outline {
    border: solid 1px #1f2f3b;
    color: #1f2f3b;
    background-color: transparent;
  }  

  .btn-approve {
    border: solid 1px #8ec396;
    background-color: #8ec396;
  }

  .btn-reject {
    border: solid 1px #d45b5b;
    background-color: #d45b5b;
  } 

  .btn-approve-outline {
    display: block;
    background: transparent;
    border: solid 1px #8ec396;
    width: 140px;
    height: 30px;
    border-radius: 10px;
    line-height: 30px;
    text-align: center;
    color: #8ec396;
  }

  input[type='radio'] {
    border: solid 0.5px #707070;
  } 

  textarea {
    width: 100%;
    padding: 22px;
    font-size: 20px;
    font-weight: 300;
    border: solid 1px #70707077;
    border-radius: 10px;
    resize: none;
  }

  /* react-toast */
  div.toast {

    div.toast-info {
      border-radius: 10px;
      padding: 25px;
    }

    div.toast-progress {
      height: 2px !important;
      background: #fff;
    }
  .Toastify__toast--default {
    background: #1F2F3B !important;
    }
  .Toastify__toast--info {
    background: #1F2F3B !important; }
   .Toastify__toast--success {
    background: #8ec396 !important; }  
  .Toastify__toast--error {
    background: #d45b5b !important; }

    @media (${maxXs}) {
      max-width: 70vw;
      margin: 0 0 5vh 5vw;
    }

  }

  /* Lock Auth0 */
  div#auth0-login-container {
    margin-top: 0 !important;
  }

  div.auth0-lock-header-bg-solid {
    background-color: transparent !important;
  }

  div.auth0-lock-header-bg {
    background: unset !important;
  }

  div.auth0-lock-header-bg-blur {
    background: unset !important;
    background-image: unset !important;
  }

  div.auth0-lock.auth0-lock.auth0-lock-opened-in-frame {
    width: 100% !important;
  }

  div.auth0-lock-cred-pane-internal-wrapper {  
    height: auto !important;
    max-height: unset !important;
    overflow: auto;
  }

  div.auth0-lock-content-wrapper {
    flex-grow: unset !important;
    min-height: 270px;
  }

  button.auth0-lock-submit {
    padding: 0 !important;
  }

  @media(${maxXs}) { 
    form.auth0-lock-widget {
      width: 100% !important;
      min-width: 300px;
      max-width: 500px;
    }
  } 

  /* End Lock Auth0 */
`
