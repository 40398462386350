import { action } from 'typesafe-actions'
import { FiltersTypes } from './types'

export const setCustomerIdFilter = (customerIdFilter: string) =>
  action(FiltersTypes.SET_CUSTOMER_ID_FILTER, customerIdFilter)
export const setStatus = (status: string) =>
  action(FiltersTypes.SET_STATUS, status)
export const setNameOrSku = (nameOrSku: string) =>
  action(FiltersTypes.SET_NAME_OR_SKU, nameOrSku)
export const setPage = (page: number) => action(FiltersTypes.SET_PAGE, page)
