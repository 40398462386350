import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import './config/i18n'

import { ToastContainer } from 'react-toastify'
import history from './services/history'

import './config/ReactotronConfig'
import Routes from './routes'

import GlobalStyle from './styles/global'

import { persistor, store } from './store'

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <ToastContainer
            autoClose={3000}
            position="bottom-left"
            className="toast"
            toastClassName="toast-info"
            progressClassName="toast-progress"
          />
        </Router>
      </PersistGate>
    </Provider>
  )
}

export default App
