import { action } from 'typesafe-actions'
import { PendingReviewsTypes } from './types'
import { UpdateStatusItem, Product } from '../product/types'

export const loadPendingReviewsRequest = (payload: {
  page: number
  resultsPerPage: number
  nameOrSku: string
  customerIdFilter: string
}) => action(PendingReviewsTypes.LOAD_USER_PENDING_REVIEWS_REQUEST, payload)
export const loadPendingReviewsSuccess = (products: Product[]) =>
  action(PendingReviewsTypes.LOAD_PRODUCTS_SUCCESS, { products })
export const setTotalItems = (totalItems: Product[]) =>
  action(PendingReviewsTypes.SET_TOTAL_ITEMS, { totalItems })

export const updateProductStatusRequest = (
  updateStatusItem: UpdateStatusItem
) => action(PendingReviewsTypes.UPDATE_PRODUCT_STATUS_REQUEST, updateStatusItem)

export const approveAllPendenciesRequest = () =>
  action(PendingReviewsTypes.APPROVE_ALL_PENDENCIES_REQUEST)

export const changeInfoBarVisibility = (isVisible: boolean) =>
  action(PendingReviewsTypes.CHANGE_INFO_BAR_VISIBILITY, { isVisible })

export const setIsUpdating = (isUpdating: boolean) =>
  action(PendingReviewsTypes.SET_IS_UPDATING, { isUpdating })
