import { Reducer } from 'redux'
import { LoadTypes } from './types'
import { PendingReviewsTypes } from '../pendingReviews/types'
import { UserTypes } from '../user/types'
import { ModelTypes } from '../models/types'
import { AuthTypes } from '../auth/types'

interface LoadingState {
  loading: boolean
}

const INITIAL_STATE = {
  loading: false,
}

const reducer: Reducer<LoadingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoadTypes.SET_LOADING_ACTIVE:
      return { ...state, loading: true }
    case LoadTypes.SET_LOADING_INACTIVE:
      return { ...state, loading: false }
    case PendingReviewsTypes.LOAD_USER_PENDING_REVIEWS_REQUEST:
      return { ...state, loading: true }
    case PendingReviewsTypes.LOAD_PRODUCTS_SUCCESS:
      return { ...state, loading: false }
    case ModelTypes.LOAD_MODELS_REQUEST:
      return { ...state, loading: true }
    case ModelTypes.LOAD_BASE_MODELS_SUCCESS:
      return { ...state, loading: false }
    case UserTypes.GET_SUMMARY_REQUEST:
      return { ...state, loading: true }
    case UserTypes.GET_SUMMARY_SUCCESS:
      return { ...state, loading: false }
    case AuthTypes.SESSION_REQUEST:
      return { ...state, loading: true }
    case AuthTypes.SESSION_SUCCESS:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default reducer
