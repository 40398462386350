import styled from 'styled-components/macro'

import { maxXs } from '../../styles/breakPoints'

interface Props {
  visible?: boolean
}

export const ModalContainer = styled.div`
  display: ${(props: Props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 0 20px;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
`
export const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto 0 auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 780px;
  text-align: center;
  border: solid 1px #707070;

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
  }
`
export const CloseButton = styled.button`
  position: absolute;
  top: 30px;
  right: 30px;
  background: transparent;
  border: none;

  @media (${maxXs}) {
    top: 12px;
    right: 12px;
  }
`
export const ModalBody = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  padding: 20px;
  height: 356px;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  overflow: hidden scroll;

  canvas {
    width: 100% !important;
    height: auto !important;
  }

  div {
    color: #707070;
    font-size: 14px;
    line-height: 18px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #cecece;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`

export const ModalFooter = styled.div`
  margin-top: 30px;

  button {
    width: 168px;
    height: 40px;
    border-radius: 10px;
    background-color: #1f2f3b;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 15px;

    &:disabled {
      background-color: #ccc;
    }
  }
`
