import React from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Lock from './Lock'
import isAuthenticated from './isAuthenticated'
import { AUTH_CONFIG } from './auth0-variables'
import Loading from '../components/Loading/index'

import { BgContainer } from '../pages/Dashboard/styles'

interface Props extends React.Props<object> {
  match: { params: { sessionType: string } }
  location: { hash: string }
}

const Login = (props: Props) => {
  const { t, ready } = useTranslation('config')
  const { match, location } = props
  const { sessionType } = match.params

  if (sessionType === AUTH_CONFIG.allowSignUpKey) {
    localStorage.setItem('sessionType', sessionType)
  } else {
    localStorage.removeItem('sessionType')
  }

  return (
    <Loading showChildrens initShowing>
      <BgContainer hasHeader={false}>
        {ready ? (
          isAuthenticated() ? (
            <div>
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: location },
                }}
              />
            </div>
          ) : (
            <div>
              <Lock
                locale={t('auth0.locale')}
                welcomeMessage={t('auth0.welcomeMessage')}
                location={location}
                sessionType={sessionType}
              />
            </div>
          )
        ) : (
          <div />
        )}
      </BgContainer>
    </Loading>
  )
}

export default Login
