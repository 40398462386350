import React, { ReactElement, useEffect, useState } from 'react'

interface Props {
  children: ReactElement
  isLoading: boolean
}

export default function Loading({ children, isLoading }: Props) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  return loading ? <span /> : children
}
