import styled from 'styled-components'

interface Props {
  isDragActive: boolean
}

export const DropzoneContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 380px;
  height: 220px;
  background-image: url(./dashed-border.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 6px;
  margin-top: 10px;
  background-color: ${({ isDragActive }: Props) =>
    isDragActive ? 'rgba(31,47,59,0.1)' : 'rgba(255,255,255)'};

  p {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  button {
    width: 140px;
    height: 28px;
    border-radius: 5px;
    background-color: #1f2f3b;
    border: none;
    margin: 10px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    visibility: ${({ isDragActive }: Props) =>
      isDragActive ? 'hidden' : 'visible'};
  }
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  section {
    width: 15vw;
  }
`
