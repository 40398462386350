import { Reducer } from 'redux'
import { FiltersState, FiltersTypes } from './types'

const INITIAL_STATE: FiltersState = {
  customerIdFilter: '',
  nameOrSku: '',
  status: '',
  page: 1,
}

const reducer: Reducer<FiltersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FiltersTypes.SET_CUSTOMER_ID_FILTER:
      return {
        ...state,
        customerIdFilter: action.payload,
        page: 1,
      }
    case FiltersTypes.SET_NAME_OR_SKU:
      return {
        ...state,
        nameOrSku: action.payload,
        page: 1,
      }
    case FiltersTypes.SET_STATUS:
      return { ...state, status: action.payload, page: 1 }
    case FiltersTypes.SET_PAGE:
      return { ...state, page: action.payload }
    default:
      return state
  }
}

export default reducer
