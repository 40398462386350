import React from 'react'

import { CustomCheckbox } from './styles'

interface Props {
  disabled?: boolean
  value?: string
  checked?: boolean
  onChange?: Function
}

const Checkbox = ({ disabled, value, checked, onChange }: Props) => (
  <CustomCheckbox className="checkbox-container">
    <input
      type="checkbox"
      value={value}
      onChange={(e) => onChange(e)}
      disabled={disabled}
      checked={checked}
    />
    <span className="checkmark" />
  </CustomCheckbox>
)

export default Checkbox
