import consoleApi from '../../../../services/api'

interface Info {
  url: string
  blob: Blob
  name: string
  sizeBytes: number
  loaded: number
  total: number
}

interface Params {
  file: File
  onProgress: (info: Info) => void
  onError?: (info: Info) => void
}

export default function upload({
  file,
  onProgress,
  onError = () => undefined,
}: Params): { abort: () => void } {
  const info = {
    name: file.name,
  } as Info

  const xhr = new XMLHttpRequest()
  ;(async () => {
    const {
      data: { fields, url },
    } = await consoleApi.post('/upload', {
      filename: encodeURIComponent(file.name),
      customerSlug: 'console',
    })

    info.blob = file
    info.sizeBytes = file.size
    info.url = `https://storage.googleapis.com/${fields.bucket}/${fields.key}`

    xhr.upload.addEventListener('progress', ({ loaded, total }) => {
      info.loaded = loaded
      info.total = total
      onProgress(info)
    })

    const formData = new FormData()
    xhr.open('POST', url, true)

    Object.keys(fields).forEach((field) => {
      formData.append(field, fields[field])
    })

    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhr.onerror = () => onError(info)
    formData.append('file', info.blob)
    xhr.send(formData)
  })()

  return { abort: () => xhr.abort() }
}
