import { Reducer } from 'redux'
import { AuthState, AuthTypes, Auth0Data } from './types'

const INITIAL_STATE: AuthState = {
  auth0Data: {} as Auth0Data,
  error: false,
  loading: false,
  signed: false,
}

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.SESSION_REQUEST:
      return { ...state, loading: true }
    case AuthTypes.SESSION_SUCCESS:
      return { ...state, loading: false }
    case AuthTypes.SESSION_FAILURE:
      return { ...state, loading: false }
    case AuthTypes.LOAD_AUTH0_DATA:
      return { ...state, auth0Data: action.payload.data }
    case AuthTypes.SET_LOGGED:
      return { ...state, signed: action.payload.signed }
    case AuthTypes.LOGOUT:
      return {
        signed: false,
        loading: false,
        error: false,
        auth0Data: {},
      }
    default:
      return state
  }
}

export default reducer
