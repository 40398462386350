export enum FiltersTypes {
  'SET_CUSTOMER_ID_FILTER' = '@filters/SET_CUSTOMER_ID_FILTER',
  'SET_STATUS' = '@filters/SET_STATUS',
  'SET_NAME_OR_SKU' = '@filters/SET_NAME_OR_SKU',
  'SET_PAGE' = '@filters/SET_PAGE',
}

export interface FiltersState {
  customerIdFilter?: string
  nameOrSku?: string
  status?: string
  page: number
}
