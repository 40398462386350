import { Reducer } from 'redux'
import { ModalTypes, ModalState } from './types'

const INITIAL_STATE: ModalState = {
  show: false,
  name: '',
  sku: '',
  uploadFiles: [],
}

const reducer: Reducer<ModalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalTypes.SHOW_MODAL:
      return { ...state, show: action.payload.show }
    case ModalTypes.CREATE_PRODUCT_SUCCESS:
      return INITIAL_STATE
    case ModalTypes.SET_NAME:
      return { ...state, name: action.payload.name }
    case ModalTypes.SET_SKU:
      return { ...state, sku: action.payload.sku }
    case ModalTypes.ADD_FILE:
      if (
        state.uploadFiles.find((f) => f.file.name === action.payload.file.name)
      ) {
        return state
      }
      return {
        ...state,
        uploadFiles: [
          ...state.uploadFiles,
          { file: action.payload.file, percent: 0, url: '' },
        ],
      }
    case ModalTypes.REMOVE_FILE:
      return {
        ...state,
        uploadFiles: state.uploadFiles.filter(
          (f) => f !== action.payload.uploadFile
        ),
      }
    case ModalTypes.UPDATE_FILE:
      return {
        ...state,
        uploadFiles: state.uploadFiles.map((f) =>
          f.file === action.payload.file
            ? {
                ...f,
                url: action.payload.url,
                percent: action.payload.percent,
              }
            : f
        ),
      }
    default:
      return state
  }
}

export default reducer
