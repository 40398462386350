import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ApplicationState } from '../../store'
import { Select } from './styles'
import { getCustomersRequest } from '../../store/modules/user/actions'
import { setCustomerIdFilter } from '../../store/modules/filters/actions'
import ContextLoading from '../Loading/ContextLoading'

export default function CustomerFilter() {
  const { customers } = useSelector((state: ApplicationState) => state.user)
  const { customerIdFilter } = useSelector(
    (state: ApplicationState) => state.filters
  )
  const { t, ready } = useTranslation(['components/customerFilter/index'])
  const dispatch = useDispatch()

  const { type: userType } = useSelector(
    (state: ApplicationState) => state.user
  )
  const customerLimitFilter = 1000

  useEffect(() => {
    dispatch(getCustomersRequest({ limit: customerLimitFilter }))
  }, [dispatch])

  return (
    <ContextLoading isLoading={!ready}>
      {userType !== 'CLIENT' && (
        <div className="filterClient">
          <Select
            onChange={(e) => {
              dispatch(setCustomerIdFilter(e.target.value))
            }}
            value={customerIdFilter}
          >
            <option value="">
              {t('pendingReviews/subHeader:statusFilterClient')}
            </option>
            {customers &&
              customers.map((customer) => (
                <option value={customer._id} key={customer._id}>
                  {t(customer.name)}
                </option>
              ))}
            <option value="">
              {t('components/customerFilter/index:allClients')}
            </option>
          </Select>
        </div>
      )}
    </ContextLoading>
  )
}
