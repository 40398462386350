import styled from 'styled-components/macro'
import { Grid } from 'react-flexbox-grid'
import { maxSm } from '../../../styles/breakPoints'

export const RejectMenuStyled = styled(Grid)`
  position: relative;
  margin-bottom: 0px;
  max-width: 980px;

  button.reject {
    display: none;
  }

  h2.title {
    display: none;
  }

  button.close-reject-menu {
    display: none;
    position: absolute;
    background: transparent;
    border: none;
    right: 0;
    top: -30px;
  }

  button {
    margin: 0 auto;
  }

  div.reject-data {
    position: relative;

    textarea {
      bottom: 0;
      min-height: 140px;
    }

    div.reasons {
      display: flex;
      div.first-group {
        margin-right: 30px;
      }

      label {
        font-size: 18px;
      }
    }

    & + button {
      margin: 0 0 10px auto;
    }
  }

  @media (${maxSm}) {
    position: fixed;
    width: 100%;
    top: 70px;
    background-color: #fff;
    opacity: 0.98;
    z-index: 999;

    height: 100%;
    padding: 40px 25px;
    margin-bottom: 30px;

    div.reject-data {
      height: calc(40vh + 85px);
      max-height: 315px;

      textarea {
        position: absolute;
        bottom: 0;
      }
    }

    h2.title {
      display: block;
    }

    button.close-reject-menu {
      display: block;
      top: 10px;
      right: 22px;
    }

    div.reject-data {
      margin-bottom: 20px;
      width: 100%;
      max-width: 400px;

      div.reasons {
        width: 100%;
        justify-content: space-between;

        label {
          line-height: 18px;
          margin-bottom: 12px;
        }
      }

      & + button {
        margin: 0 auto;
      }
    }
  }
`
