/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import user from './user/sagas'
import products from './pendingReviews/sagas'
import models from './models/sagas'
import modal from './modal/sagas'

export default function* rootSaga() {
  return yield all([auth, user, products, models, modal])
}
