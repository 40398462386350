import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ContextLoading from '../Loading/ContextLoading'

import { Header, Nav } from './styles'
import logo from '../../assets/images/whitelogo.png'
import { setNameOrSku, setStatus } from '../../store/modules/filters/actions'

export default function () {
  const { t, ready } = useTranslation(['components/header/index'])
  const [menuToggle, setMenuToggle] = useState(false)

  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    setMenuToggle(false)
  }, [location])

  function handleMenuToggleClick() {
    setMenuToggle(!menuToggle)
  }

  return (
    <ContextLoading isLoading={!ready}>
      <Header>
        <div className="brand">
          <Link to="/dashboard">
            <img src={logo} alt="R2U" />
          </Link>
        </div>
        <div className={menuToggle ? 'menu-section on' : 'menu-section'}>
          <button
            type="button"
            className="menu-toggle"
            onClick={handleMenuToggleClick}
          >
            <div className="first-bar" />
            <div className="middle-bar" />
            <div className="last-bar" />
          </button>
          <Nav>
            <ul>
              <li>{t('nav.menu')}</li>
              <li>
                <Link
                  to="/models"
                  onClick={() => {
                    dispatch(setStatus(''))
                    dispatch(setNameOrSku(''))
                  }}
                >
                  {t('nav.models')}
                </Link>
              </li>
              <li>
                <Link
                  to="/review"
                  onClick={() => {
                    dispatch(setStatus(''))
                    dispatch(setNameOrSku(''))
                  }}
                >
                  {t('nav.pendingReviews')}
                </Link>
              </li>
              <li>
                <Link to="/logout">{t('nav.logout')}</Link>
              </li>
            </ul>
          </Nav>
        </div>
      </Header>
    </ContextLoading>
  )
}
