import styled from 'styled-components/macro'
import { Row, Grid } from 'react-flexbox-grid'
import { maxSm, maxXs } from '../../styles/breakPoints'

interface Props {
  visible: boolean
}

export const ResponsiveContainer = styled.div`
  max-width: 980px;
  margin: auto;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
`

export const SubHeaderStyled = styled.div`
  height: 100%;
  font-family: azo-sans-web, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
  width: 983px;
  margin-bottom: 40px;
  margin: 30px auto;

  div {
    display: flex;

    strong {
      margin-left: 15px;
    }
  }
`

export const Container = styled(Grid)`
  margin-bottom: 40px;

  @media (${maxSm}) {
    padding: 0 20px;
  }

  @media (${maxXs}) {
    margin-left: 10px;
  }
`

export const SearchInfo = styled.div`
  margin-top: 30px;
`

export const AccordionList = styled(Row)`
  transition: 0.7s;

  &.initial-delete {
    transition: 0.7s;
    opacity: 0.4;
  }

  &.permanent-delete-transition {
    opacity: 0;
  }

  &.permanent-deleted {
    display: none;
  }

  div.active {
    z-index: 1;
  }

  @media (${maxSm}) {
    div.checkbox.hide-checkbox {
      display: none;
    }
  }

  @media (${maxXs}) {
    div.checkbox {
      width: 22px;
    }
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props: Props) => (props.visible ? '30px auto' : '15px auto')};
  display: ${(props: Props) => (props.visible ? 'flex' : 'none')};
  max-width: 980px;

  p.totalProducts {
    font-size: 14px;
    font-weight: 300;

    span {
      margin-left: 8px;
    }
  }

  ul.pagination-nav {
    display: flex;
    margin: 0;

    li {
      list-style: none;
      font-size: 14px;
      padding: 2px 8px;
      font-weight: 300;
      cursor: pointer;

      &.disabled {
        opacity: 0.3 !important;
      }

      &.pagination-active {
        font-weight: 700;
      }

      a {
        color: #1f2f3b;
        text-decoration: none;

        svg {
          margin-top: 3px;
        }
      }
    }
  }

  @media (${maxSm}) {
    margin: 15px 0;
  }
`
