import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import Cache from 'i18next-localstorage-cache'
import sprintf from 'i18next-sprintf-postprocessor'
import i18n from 'i18next'

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(Cache)
  .use(sprintf)
  .init({
    debug: false,
    react: {
      useSuspense: false,
      wait: true,
    },
    lng: 'pt_br',
    fallbackLng: 'pt_br',
    whitelist: ['en', 'pt_br'],
    preload: ['pt_br', 'en'],
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'dashboard:index',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiloading: true,
    },
    cache: {
      enabled: true,
      expirationTime: 24 * 60 * 60 * 1000,
    },
  })

i18n.services.pluralResolver.addRule('pt_br', {
  name: 'pt_br',
  numbers: [1, 2],
  plurals(n: number) {
    return Number(n !== 1)
  },
})

export default i18n
