import { Product } from '../product/types'

export enum PendingReviewsTypes {
  'LOAD_USER_PENDING_REVIEWS_REQUEST' = '@pendingReviews/LOAD_USER_PENDING_REVIEWS_REQUEST',
  'LOAD_PRODUCTS_SUCCESS' = '@pendingReviews/LOAD_PRODUCTS_SUCCESS',
  'UPDATE_PRODUCT_STATUS_REQUEST' = '@pendingReviews/UPDATE_PRODUCT_STATUS_REQUEST',
  'APPROVE_ALL_PENDENCIES_REQUEST' = '@pendingReviews/APPROVE_ALL_PENDENCIES_REQUEST',
  'SET_TOTAL_ITEMS' = '@pendingReviews/SET_TOTAL_ITEMS',
  'CHANGE_INFO_BAR_VISIBILITY' = '@pendingReviews/CHANGE_INFO_BAR_VISIBILITY',
  'SET_IS_UPDATING' = '@pendingReviews/SET_IS_UPDATING',
}

export interface PendingReviewState {
  products: Product[]
  totalItemsCount: number
  loaded: boolean
  infoBar: boolean
  isUpdating: boolean
}
