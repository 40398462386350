import React from 'react'
import { Col } from 'react-flexbox-grid'
import { useTranslation } from 'react-i18next'
import { SubHeaderStyled } from './styles'

export default function () {
  const { t } = useTranslation(['models/index'])
  return (
    <SubHeaderStyled>
      <div>
        <Col sm={5}>
          <strong>{t('model')}</strong>
        </Col>
        <Col sm={3}>
          <strong>{t('sku')}</strong>
        </Col>
        <Col sm={3}>
          <strong>{t('status')}</strong>
        </Col>
        <Col sm={1} />
      </div>
    </SubHeaderStyled>
  )
}
