import {
  all,
  call,
  put,
  takeLatest,
  select,
  takeEvery,
  delay,
} from 'redux-saga/effects'

import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import i18n from '../../../config/i18n'

import {
  loadModelsSuccess,
  loadBaseModelsSuccess,
  processFilterToggle,
} from './actions'

// eslint-disable-next-line import/no-cycle
import api from '../../../services/api'

import { ModelTypes, ModelsState } from './types'

import { updateProductStatusRequest } from '../pendingReviews/actions'

// eslint-disable-next-line import/no-cycle
import { setProductsData } from '../product/sagas'

export function* loadModels(action) {
  yield i18n.loadNamespaces(['sagas', 'config'])
  const { payload } = action
  const { page, resultsPerPage, status, nameOrSku, customerIdFilter } = payload

  try {
    const { data: products } = yield call(api.get, `user/products`, {
      params: {
        page,
        resultsPerPage,
        status,
        nameOrSku,
        customerIdFilter,
      },
    })

    const { data } = products

    const productsData = setProductsData(data)

    const { totalItemsCount } = products.metadata
    yield put(loadModelsSuccess(productsData, totalItemsCount))
    yield put(processFilterToggle(true))
    yield put(loadBaseModelsSuccess(productsData))
    yield put(processFilterToggle(false))
  } catch (error) {
    if (error.status !== 401) {
      toast.error(i18n.t('sagas:models.loadModelsError'))
    }
  }
}

export function* updateMultipleModelsStatus({ payload: data }: AnyAction) {
  yield i18n.loadNamespaces(['sagas'])

  const baseModels = yield select((state: ModelsState) => state.models)

  const updatedBaseModels = baseModels.map((model) => ({
    ...model,
    status: data.find((d) => d.productId === model._id)
      ? data[0].status
      : model.status,
  }))

  const { models, totalItemsCount } = yield select(
    (state: ModelsState) => state.models
  )

  const updatedModels = models.map((model) => ({
    ...model,
    status: data.find((d) => d.productId === model._id)
      ? data[0].status
      : model.status,
  }))

  yield put(loadModelsSuccess(updatedModels, totalItemsCount))
  yield put(loadBaseModelsSuccess(updatedBaseModels))

  toast.success(i18n.t('sagas:models.multipleUpdateSuccess'))

  yield put(updateProductStatusRequest(data))
}

export function* deleteProduct({ payload: { _id, undo } }: AnyAction) {
  const { data } = yield select((state: ModelsState) => state.models)
  const { models, totalItemsCount } = yield select(
    (state: ModelsState) => state.models
  )

  const updatedBaseModels = data.map((model) => ({
    ...model,
    removalStatus:
      model._id === _id ? (!undo ? 'initial-delete' : '') : model.removalStatus,
  }))

  const updatedModels = models.map((model) => ({
    ...model,
    removalStatus:
      model._id === _id ? (!undo ? 'initial-delete' : '') : model.removalStatus,
  }))

  yield put(loadModelsSuccess(updatedModels, totalItemsCount))
  yield put(loadBaseModelsSuccess(updatedBaseModels))

  yield undo
    ? call(api.patch, `/products/${_id}`, { deleted: false })
    : call(api.delete, `/products/${_id}`)
}

export function* refreshInMemoryDeletedItens({ payload: { _id } }: AnyAction) {
  const { baseModels } = yield select((state: ModelsState) => state.models)
  const { models, totalItemsCount } = yield select(
    (state: ModelsState) => state.models
  )

  const permanentDeletedModelsTransition = models.map((model) => ({
    ...model,
    removalStatus:
      model.removalStatus === 'initial-delete' && model._id === _id
        ? `permanent-delete-transition`
        : model.removalStatus,
  }))

  const permanentDeletedBaseModelsTransition = baseModels.map((model) => ({
    ...model,
    removalStatus:
      model.removalStatus === 'initial-delete' && model._id === _id
        ? `permanent-delete-transition`
        : model.removalStatus,
  }))

  yield put(
    loadModelsSuccess(permanentDeletedModelsTransition, totalItemsCount)
  )
  yield put(loadBaseModelsSuccess(permanentDeletedBaseModelsTransition))

  yield delay(800)

  const permanentDeletedModels = permanentDeletedModelsTransition.map(
    (model) => ({
      ...model,
      removalStatus:
        model.removalStatus === 'permanent-delete-transition' &&
        model._id === _id
          ? `permanent-deleted`
          : model.removalStatus,
    })
  )

  const permanentDeletedBaseModels = permanentDeletedBaseModelsTransition.map(
    (model) => ({
      ...model,
      removalStatus:
        model.removalStatus === 'permanent-delete-transition' &&
        model._id === _id
          ? `permanent-deleted`
          : model.removalStatus,
    })
  )

  yield put(loadModelsSuccess(permanentDeletedModels, totalItemsCount))
  yield put(loadBaseModelsSuccess(permanentDeletedBaseModels))
}

export function* toggleProductRunningOrPaused({ payload }: AnyAction) {
  const { status, productId } = payload

  const { baseModels } = yield select((state: ModelsState) => state.models)
  const { models, totalItemsCount } = yield select(
    (state: ModelsState) => state.models
  )

  const updatedBaseModels = baseModels.map((model) => ({
    ...model,
    status: model._id === productId ? status : model.status,
  }))

  const updatedModels = models.map((model) => ({
    ...model,
    status: model._id === productId ? status : model.status,
  }))

  yield put(updateProductStatusRequest({ productId, status }))
  yield put(loadBaseModelsSuccess(updatedBaseModels))
  yield put(loadModelsSuccess(updatedModels, totalItemsCount))
}

export default all([
  takeLatest(ModelTypes.LOAD_MODELS_REQUEST, loadModels),
  takeLatest(
    ModelTypes.UPDATE_MULTIPLE_MODEL_STATUS_REQUEST,
    updateMultipleModelsStatus
  ),
  takeLatest(ModelTypes.SEARCH_MODELS_REQUEST, loadModels),
  takeLatest(
    ModelTypes.TOGGLE_PRODUCT_RUNNING_OR_PAUSED_REQUEST,
    toggleProductRunningOrPaused
  ),
  takeEvery(ModelTypes.DELETE_PRODUCT_REQUEST, deleteProduct),
  takeLatest(
    ModelTypes.REFRESH_IN_MEMORY_DELETED_ITENS_REQUEST,
    refreshInMemoryDeletedItens
  ),
])
