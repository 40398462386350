import { action } from 'typesafe-actions'
import { UserTypes } from './types'

export const acceptTermsRequest = () => action(UserTypes.ACCEPT_TERMS_REQUEST)
export const acceptTermsSuccess = () => action(UserTypes.ACCEPT_TERMS_SUCCESS)
export const getSummaryRequest = () => action(UserTypes.GET_SUMMARY_REQUEST)
export const getSummarySuccess = (summary: {}) =>
  action(UserTypes.GET_SUMMARY_SUCCESS, { summary })
export const getCustomersRequest = (filters: { limit: number }) =>
  action(UserTypes.GET_CUSTOMER_REQUEST, { filters })
export const getCustomersSuccess = (customers: {}) =>
  action(UserTypes.GET_CUSTOMER_SUCCESS, { customers })
