export enum AuthTypes {
  'SESSION_REQUEST' = '@auth/SESSION_REQUEST',
  'SESSION_SUCCESS' = '@auth/SESSION_SUCCESS',
  'SESSION_FAILURE' = '@auth/SESSION_FAILURE',
  'SESSION_EXPIRED' = '@auth/SESSION_EXPIRED',
  'LOAD_AUTH0_DATA' = '@auth/LOAD_AUTH0_DATA',
  'SET_LOGGED' = '@auth/SET_LOGGED',
  'LOGOUT' = '@auth/LOGOUT',
}

export interface Auth0Data {
  accessToken: string
  email: string
  idToken: string
  auth0Id: string
  expiresAt: number
}

export interface AuthState {
  readonly auth0Data: Auth0Data
  readonly loading: boolean
  readonly error: boolean
  readonly signed: boolean
}
