import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  fileName: string
  loadingMessage?: string
}

export default function App({ fileName, loadingMessage = '' }: Props) {
  const [numPages, setNumPages] = useState(0)

  const onDocumentLoadSuccess = ({ numPages: contPages }) => {
    setNumPages(contPages)
  }

  return (
    <Document
      file={fileName}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={loadingMessage}
      renderMode="svg"
    >
      {Array.from(Array(numPages).keys()).map((n) => (
        <Page key={n} noData="" loading="" pageNumber={n + 1} width={660} />
      ))}
    </Document>
  )
}
