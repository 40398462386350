import styled from 'styled-components/macro'

export const Select = styled.select`
  width: 220px;
  height: 28px;
  padding: 5px;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  border: solid 1px #ccc;
  margin: 0 16px;
  font-weight: 300;
`
