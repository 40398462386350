import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

import { Loader } from './styles'

interface Props {
  children: ReactElement
  showChildrens?: boolean
  initShowing?: boolean
}

export default function Loading({
  children,
  showChildrens = false,
  initShowing = false,
}: Props) {
  const [loading, setLoading] = useState(true)
  const isLoading = useSelector(
    (state: ApplicationState) => state.loading.loading
  )

  useEffect(() => {
    setLoading(isLoading || initShowing)
  }, [isLoading, initShowing])

  return loading ? (
    <div>
      <Loader>
        <img src="/logo-real2u.png" alt="" />
      </Loader>
      {showChildrens && children}
    </div>
  ) : (
    children
  )
}
