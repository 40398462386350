import styled from 'styled-components/macro'
import { Row, Col } from 'react-flexbox-grid'

import { maxXs, maxSm, maxMd } from '../../../styles/breakPoints'

import { PRODUCT_STATUS } from '../../../store/modules/product/types'

interface OverlayProps {
  effect: string
}

export const ReviewItem = styled(Row)`
  position: relative;
  border-radius: 20px;
  transition: 0.5s linear;
  transition-delay: 1s;
  margin: 10px 0 10px;

  &.hide {
    max-height: 0px;
    padding: 0 !important;
    margin-bottom: 0 !important;
    padding: 0;
  }

  &.shadow {
    padding: 20px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
    margin-bottom: 100px;
  }

  h4 {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  div.controls {
    display: none;
    width: 100%;
    order: -2;
    top: 20px;
    right: 10px;
    margin-bottom: 20px;

    button {
      background: none;
      padding: 10px 0;
      border: none;
      flex: 1;
      border-bottom: 1px solid #ccc;
      color: #ccc;

      &.active {
        color: #1f2f3b;
        border-color: #1f2f3b;
        font-weight: 500;
      }
    }
  }

  @media (${maxSm}) {
    margin: 0px 15px 15px;

    &.shadow {
      padding: 20px 15px;
    }

    div.controls {
      display: flex;
    }

    .hide-md {
      display: none;
    }

    .show-md {
      display: block !important;
    }
  }

  @media (${maxXs}) {
    &.shadow {
      padding: 20px 15px 20px 15px;
    }

    .hide-sm {
      display: none !important;
    }

    .show-sm {
      display: block !important;
    }
  }
`

export const ImagesArea = styled.div`
  display: flex;
  flex: 1;
  min-width: 220px;
  justify-content: center;
  margin-right: 10px;

  @media (${maxXs}) {
    margin-right: 0;
  }
`

export const ItemMissing = styled.div`
  max-width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
  border: dashed 3px #f0f0f0;
  border-radius: 10px;
  margin: 0 auto;

  p.missing-info {
    white-space: normal !important;
    text-overflow: unset !important;
    overflow: unset !important;
    font-weight: 500;
  }

  div.img-container {
    img {
      width: auto;
      height: 70px;
      margin: 0 auto;
    }
  }
`

export const OverlayStyled = styled.div`
  display: ${(props: OverlayProps) =>
    props.effect !== 'none' ? 'block' : 'none'};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;

  &::before {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: inherit;
    top: 0;
    left: 0;
    opacity: 0.9;
    background: ${(props: OverlayProps) =>
      props.effect === PRODUCT_STATUS.APPROVED ? '#8ec396' : '#d45b5b'};
    z-index: 6;
  }

  div.overlay-base {
    display: block;
    position: absolute;
    z-index: 6;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 160px;

    div.overlay-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 26;

      span {
        font-size: 21px;
        font-weight: 300;
        color: #fff;
        z-index: 20;
      }
    }
  }
`
export const SubHeader = styled(Row)`
  box-shadow: 0 4px 8px 0 rgba(163, 163, 163, 0.5);
  border: solid 0.5px #ebebeb;

  div.actions {
    display: flex;
    margin: 0 auto;
    min-width: 400px;
    height: 80px;
    justify-content: space-around;
    align-items: center;
    width: 90%;
  }

  h3 {
    font-size: 22px;
  }

  a.btn-outline {
    display: block;
    border: solid 1px #8ec396;
    width: 140px;
    height: 30px;
    border-radius: 10px;
    line-height: 30px;
    text-align: center;
    color: #8ec396;
  }
`

export const PageInfo = styled(Col)`
  margin: 32px 0;

  p {
    text-align: center;
    font-size: 17px;
    font-weight: 300;

    strong {
      font-weight: 500;
    }
  }
`
export const ReviewTitle = styled(Col)`
  display: flex;
  align-items: center;
  height: 80px;

  img {
    height: 60px;
    margin-right: 20px;
    border-radius: 10px;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    margin-right: 16px;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  small {
    font-size: 12px;
    font-weight: 300;

    span {
      margin-right: 5px;
    }
  }

  @media (${maxSm}) {
    display: none;
  }
`

export const ProductInfo = styled(Col)`
  div.content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 30px 0 30px 0;

    div.info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left: 20px;

      strong.link {
        a {
          color: #000;
        }
      }

      div.info-item {
        display: flex;
        align-items: baseline;

        &.product-name-sm {
          display: none;
        }

        p {
          flex: 1;
          font-size: 18px;
          font-weight: 300;
          margin-top: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        strong {
          flex: 2;
          margin-left: 3px;
          font-size: 16px;

          a {
            text-decoration: none;
            color: #000;

            span {
              max-width: 120px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          svg {
            margin: 0 0 4px 5px;
            font-size: 14px;
          }
        }

        strong.dimensions {
          max-height: 42px;
          white-space: normal;
          overflow: auto;
          margin-top: 12px;

          ::-webkit-scrollbar {
            width: 2px;
          }

          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          ::-webkit-scrollbar-thumb {
            background: #cecece;
            border-radius: 10px;
          }

          ::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }

        &.ar-control {
          display: none;
          align-items: flex-end;
        }
      }
    }
  }

  @media (${maxSm}) {
    div.info-item.ar-control {
      display: flex !important;
    }
  }

  @media (${maxXs}) {
    display: flex !important;

    div.content {
      flex-direction: column;
      margin: unset;

      div.info {
        margin-top: 20px;
      }
    }

    div.info-item.product-name-sm {
      display: flex !important;

      a span {
        margin: 12px 0 !important;
        white-space: normal !important;
        overflow: visible !important;
        max-width: 100% !important;
        font-size: 18px !important;
      }
    }

    h4.title {
      display: none;
    }
  }
`

export const ReviewInfo = styled(Col)`
  div.qrcode {
    display: flex;
    flex-direction: column;

    img {
      position: absolute;
      margin: -10px 0px 0px -9px;
    }
  }
  div.card-qrcode {
    position: absolute;
    padding: 10px;
    background-color: #f0f0f0;
    top: calc(4% + 2px);
    border-radius: 5%;
    left: calc(-25% - 142px);
    right: calc(56% - 4px);
  }

  div.images {
    position: relative;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    border-left: solid 0.1px #eee;
    padding-left: 10px;

    a.link-viewer {
      position: absolute;
      top: 10px;
      left: 10px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        background-color: #fff;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }

    button.action-button {
      position: absolute;
      background: none;
      border: none;
      z-index: 4;
    }

    button.button-3d {
      display: none;
      bottom: 9px;
      left: 10px;
      border-radius: 50%;
      font-size: 17px;
      font-weight: 500;
      color: #fff;

      width: 25px;
      height: 25px;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      background-color: #1f2f3b;

      img {
        width: 13px;
      }

      &.active {
        opacity: 1;
      }
    }

    button.button-ar {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;

      & > :first-child {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .ar-icon {
        width: 20px;
      }
    }

    div.render-box {
      margin: 0 auto;
      max-width: 220px;
      position: relative;
    }

    div.model-viewer {
      display: flex;
      justify-content: center;
      flex: 1;
      height: 220px;
      img.thumbnailUrl {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }

      div.viewer-box {
        position: relative;
        max-width: 220px;
        height: inherit;
        margin: 0 auto;

        .clickPoster {
          bottom: 0;
          position: absolute;
          background-color: #000;
          padding: 5px;
          border-radius: 5px;
          margin-top: 90px;
          margin: 10px;
          cursor: pointer;
          display: inline-block;
          z-index: 1;
          left: 0;

          .iconDownload {
            margin-bottom: -3px;
            color: #fff;
          }
        }
        span {
          text-align: center;
          align-self: center;
          margin-left: 10px;
          font-weight: bold;
          color: #fff;
        }
      }

      .img-overlay {
        width: 220px !important;
        height: 220px !important;
      }

      model-viewer {
        min-width: 220px !important;
        min-height: 220px !important;
      }
    }

    img.model {
      display: block;
      width: 220px;
      max-width: 220px;
      border-radius: 10px;
    }
  }

  @media (${maxMd}) {
    div.images {
      border-left: none;
    }

    div.images button.button-ar {
      display: flex;
    }
  }

  @media (${maxXs}) {
    order: -1;

    div.images {
      margin-top: unset;

      button.button-3d {
        display: flex;
      }
    }

    h4.title {
      display: none;
    }
  }
`

export const CustomReviewActions = styled(Col)`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  a {
    display: block;
    width: 140px;
    height: 40px;
    border-radius: 10px;
    line-height: 40px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    font-weight: 500;
    margin-left: 20px;
  }

  a.approve {
    border: solid 1px #8ec396;
    background-color: #8ec396;
  }

  a.reprove {
    border: solid 1px #d45b5b;
    background-color: #d45b5b;
  }

  @media (${maxMd}) {
    justify-content: center;
    margin-top: 20px;
  }
`
