import React from 'react'

import { useTranslation } from 'react-i18next'
import { ApproveConfirmation as ApproveConfirmationStyled } from './styles'

interface Props {
  show: boolean
  handleApprove: () => void
}

const ApproveConfirmation: React.FC<Props> = ({
  show,
  handleApprove,
}: Props) => {
  const { t } = useTranslation('models/approveConfirmation')

  return show ? (
    <ApproveConfirmationStyled>
      <h3>{t('approveConfirmationQuestion')}</h3>
      <button type="button" className="btn btn-approve" onClick={handleApprove}>
        {t('approve')}
      </button>
    </ApproveConfirmationStyled>
  ) : (
    <div />
  )
}

export default ApproveConfirmation
