import React from 'react'

import { Switch } from 'react-router-dom'

import Login from '../auth/Login'
import Logout from '../auth/Logout'

import Dashboard from '../pages/Dashboard'
import PendingReviews from '../pages/PendingReviews'
import Models from '../pages/Models'
import NotFound from '../pages/NotFound'

import Route from './Route'

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Dashboard} />
      <Route path="/login/:sessionType?" component={Login} isPrivate={false} />
      <Route path="/logout" component={Logout} isPrivate={false} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/review" component={PendingReviews} />
      <Route path="/models" component={Models} />
      <Route path="*" component={NotFound} isPrivate={false} />
    </Switch>
  )
}

export default Routes
