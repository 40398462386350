import React, { useEffect, useState, useCallback, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Grid } from 'react-flexbox-grid'

import { Animated } from 'react-animated-css'

import Pagination from 'react-js-pagination'

import { useTranslation } from 'react-i18next'

import { FaCaretRight, FaCaretLeft } from 'react-icons/fa'

import { toast } from 'react-toastify'

import JsZip from 'jszip'
import FileSaver from 'file-saver'

import TableHeader from './TableHeader'

import {
  loadModelsRequest,
  updateMultipleModelsStatusRequest,
  toggleProductRunningOrPaused,
  deleteProductRequest,
  refreshInMemoryDeletedItensRequest,
} from '../../store/modules/models/actions'

import { ApplicationState } from '../../store'

import ContextLoading from '../../components/Loading/ContextLoading'

import Loading from '../../components/Loading'

import SubHeader from '../../components/SubHeader'
import ModelItem from '../../components/Models/ModelItem'
import RejectMenu from '../../components/Models/RejectMenu'
import ApproveConfirmation from '../../components/ApproveConfirmation'

import {
  RejectData,
  PRODUCT_STATUS,
  Product,
} from '../../store/modules/product/types'

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionContent,
} from '../../components/Accordion'

import {
  AccordionList,
  PaginationContainer,
  ResponsiveContainer,
  SearchInfo,
  Container,
} from './styles'

import ReviewItem from '../../components/products/ReviewItem'
import Reasons from '../../components/products/ReasonsToReject'

import Checkbox from '../../components/_html/Checkbox'
import { setPage } from '../../store/modules/filters/actions'

export default function Models() {
  const { t, ready } = useTranslation(['models/index'])

  const subHeaderMessage = {
    title: t('title'),
    search: t('search'),
    statusFilter: t('statusFilter.general'),
    statusFilterClient: t('statusFilterClient.client'),
    addProduct: t('addProduct'),
    approveButton: t('approveButton'),
    rejectButton: t('rejectButton'),
    cancel: t('cancel'),
  }
  const [expandedItem, setExpandedItem] = useState('')
  const [hasItemChecked, setHasItemChecked] = useState(false)
  const [allItemsChecked, setAllItemsChecked] = useState(false)
  const [rejectData, setRejectData] = useState({} as RejectData)
  const [showRejectionMenu, setShowRejectionMenu] = useState(false)
  const [showApproveAllMenu, setShowApproveAllMenu] = useState(false)
  const [showCheckboxForItems, setShowCheckboxForItems] = useState(false)
  const [totalPendencies, setTotalPendencies] = useState(0)
  const [checkedProducts, setCheckedProducts] = useState({})
  const [visibleModels, setvisibleModels] = useState([])
  const [paginationLimit] = useState(10)

  const dispatch = useDispatch()

  const models = useSelector((state: ApplicationState) => state.models.models)
  const totalItemsCount = useSelector(
    (state: ApplicationState) => state.models.totalItemsCount
  )

  const processingFilter = useSelector(
    (state: ApplicationState) => state.models.processingFilter
  )
  const filters = useSelector((state: ApplicationState) => state.filters)

  useEffect(() => {
    const checkedItems = Object.values(checkedProducts)

    const searchForCheckedItems = !checkedItems.every((k) => !k)

    setHasItemChecked(searchForCheckedItems)

    if (!searchForCheckedItems && showRejectionMenu) setShowRejectionMenu(false)

    setAllItemsChecked(
      checkedItems.every((k) => k) &&
        checkedItems.length &&
        checkedItems.length === totalPendencies
    )
  }, [checkedProducts, totalPendencies, showRejectionMenu])

  function updateAllPendencies(items: Product[]) {
    setTotalPendencies(
      items.filter((item) => item.status === PRODUCT_STATUS.EXTERNAL_REVIEW)
        .length
    )
  }

  useEffect(() => {
    dispatch(
      loadModelsRequest({
        page: filters.page,
        resultsPerPage: paginationLimit,
        status: filters.status,
        nameOrSku: filters.nameOrSku,
        customerIdFilter: filters.customerIdFilter,
      })
    )
  }, [
    filters.page,
    dispatch,
    paginationLimit,
    filters.status,
    filters.nameOrSku,
    filters.customerIdFilter,
  ])

  useEffect(() => {
    updateAllPendencies(models)
    setCheckedProducts({})

    setShowCheckboxForItems(false)
    setShowApproveAllMenu(false)
    setShowRejectionMenu(false)
  }, [models, filters.page])

  useEffect(() => {
    setvisibleModels(models)
  }, [setvisibleModels, models])

  function handleCheck(e: MouseEvent<HTMLInputElement>) {
    const isChecked = e.currentTarget.checked
    const { value } = e.currentTarget
    setCheckedProducts({ ...checkedProducts, [value]: isChecked })
  }

  function handleCheckAll(e: MouseEvent<HTMLInputElement>) {
    const isChecked = e.currentTarget.checked

    setAllItemsChecked(isChecked)

    const checkBoxInitData = visibleModels
      .filter((product) => product.status === PRODUCT_STATUS.EXTERNAL_REVIEW)
      .reduce((a, b) => ({ ...a, [b._id]: isChecked }), {})

    setCheckedProducts({ ...checkBoxInitData })
  }

  function handleApproveOrReproveAllDialog(
    status: string,
    visibility: boolean
  ) {
    const action =
      status === PRODUCT_STATUS.APPROVED
        ? setShowApproveAllMenu
        : setShowRejectionMenu

    action(visibility)
  }

  function handleApproveOrReproveAll(status: string) {
    if (showRejectionMenu || showApproveAllMenu) {
      const toApprove = Object.keys(checkedProducts)
        .filter((key) => checkedProducts[key])
        .reduce(
          (a, b) => [
            ...a,
            {
              productId: b,
              status,
              ...(status === PRODUCT_STATUS.REJECTED ? rejectData : {}),
            },
          ],
          []
        )

      dispatch(updateMultipleModelsStatusRequest(toApprove))
    }
  }
  async function handleDownloadProduct(
    usdzUrl: string,
    glbUrl: string,
    renderImagesUrls: string[],
    productName: string
  ) {
    const getBlobs = async (
      urls: string[]
    ): Promise<{ name: string; blob: Blob }[]> => {
      return Promise.all(
        urls.map(async (url) => {
          const response = await fetch(url)
          const blob = await response.blob()
          return { blob, name: url.split('/').pop() }
        })
      )
    }

    const exportZip = async (blobs: { name: string; blob: Blob }[]) => {
      const zip = JsZip()
      blobs.forEach((blob) => {
        zip.file(blob.name, blob.blob)
      })
      const zipFile = await zip.generateAsync({ type: 'blob' })
      const fileName = `${productName}.zip`
      return FileSaver.saveAs(zipFile, fileName)
    }

    const urls = [...renderImagesUrls]
    if (usdzUrl) {
      urls.push(usdzUrl)
    }
    if (glbUrl) {
      urls.push(glbUrl)
    }
    const blobs = await getBlobs(urls)
    return exportZip(blobs)
  }

  const handleGetRejectData = useCallback((data) => {
    setRejectData(data)
  }, [])

  function handleToggleRunningOrPausedProduct(
    status: string,
    productId: string
  ) {
    dispatch(toggleProductRunningOrPaused(status, productId))
  }

  return (
    <>
      <SubHeader
        disableCheckAll={totalPendencies === 0}
        checkAllItems={allItemsChecked}
        messages={subHeaderMessage}
        handleAllCheck={handleCheckAll}
        rejectMenu={showRejectionMenu}
        hasItemChecked={hasItemChecked}
        handleApproveOrReproveAllDialog={handleApproveOrReproveAllDialog}
        showCheckboxForItems={showCheckboxForItems}
        handleShowCheckbuttonForItems={(status: boolean) =>
          setShowCheckboxForItems(status)
        }
      />

      <RejectMenu
        show={showRejectionMenu}
        onClick={() => setShowRejectionMenu(false)}
      >
        <Reasons
          handleGetData={handleGetRejectData}
          showTitle
          showRejectButton
          rejectButtonText={t('reject')}
          handleReject={() =>
            handleApproveOrReproveAll(PRODUCT_STATUS.REJECTED)
          }
        />
      </RejectMenu>

      <ApproveConfirmation
        show={showApproveAllMenu}
        handleApprove={() => handleApproveOrReproveAll(PRODUCT_STATUS.APPROVED)}
      />

      <ContextLoading isLoading={!ready}>
        <Loading>
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            animationInDelay={300}
            isVisible
          >
            <Container>
              <PaginationContainer
                visible={totalItemsCount > paginationLimit || filters.page > 1}
              >
                <p className="totalProducts">
                  {t('totalProducts')}
                  <span>{totalItemsCount}</span>
                </p>
                <Pagination
                  hideFirstLastPages
                  activePage={filters.page}
                  itemsCountPerPage={paginationLimit}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={3}
                  onChange={(e) => dispatch(setPage(e))}
                  activeClass="pagination-active"
                  innerClass="pagination-nav"
                  prevPageText={<FaCaretLeft />}
                  nextPageText={<FaCaretRight />}
                />
              </PaginationContainer>
              <TableHeader />
              <ResponsiveContainer className="responsive-container">
                <Accordion
                  allowZeroExpanded
                  onChange={([data]) => {
                    setExpandedItem(data)
                  }}
                >
                  {!visibleModels.length && (
                    <SearchInfo>
                      <h2 style={{ textAlign: 'center' }}>
                        {processingFilter
                          ? t('processing')
                          : t('noResultsOnSearch')}
                      </h2>
                    </SearchInfo>
                  )}
                  <Grid>
                    {visibleModels.map((product) => (
                      <AccordionList
                        key={product._id}
                        className={product.removalStatus}
                      >
                        <Col
                          className={[
                            'checkbox',
                            !showCheckboxForItems ? 'hide-checkbox' : '',
                          ].join(' ')}
                        >
                          {' '}
                          <Checkbox
                            onChange={handleCheck}
                            value={product._id}
                            checked={checkedProducts[product._id] === true}
                            disabled={
                              product.status !==
                                PRODUCT_STATUS.EXTERNAL_REVIEW ||
                              product.deleted
                            }
                          />
                        </Col>
                        <Col
                          xs
                          className={
                            expandedItem === product._id ? 'active' : ''
                          }
                        >
                          <AccordionItem uuid={product._id}>
                            <AccordionHeader>
                              <ModelItem
                                product={product}
                                expandedItem={expandedItem}
                                handleToggleRunningOrPausedProduct={
                                  handleToggleRunningOrPausedProduct
                                }
                                handleDeleteProduct={(_id) => {
                                  toast.info(
                                    'Produto removido. Clique aqui para desfazer a ação',
                                    {
                                      autoClose: 5000,
                                      onClick: () =>
                                        dispatch(
                                          deleteProductRequest(_id, true)
                                        ),
                                      onClose: () => {
                                        dispatch(
                                          refreshInMemoryDeletedItensRequest(
                                            _id
                                          )
                                        )
                                      },
                                    }
                                  )
                                  dispatch(deleteProductRequest(_id))
                                }}
                                handleDownloadProduct={handleDownloadProduct}
                              />
                            </AccordionHeader>
                            <AccordionContent>
                              <ReviewItem
                                product={product}
                                overlayEffect="none"
                                handleToggleRunningOrPausedProduct={
                                  handleToggleRunningOrPausedProduct
                                }
                                toggleAREnable
                              />
                            </AccordionContent>
                          </AccordionItem>
                        </Col>
                      </AccordionList>
                    ))}
                  </Grid>
                </Accordion>
              </ResponsiveContainer>
            </Container>
          </Animated>
        </Loading>
      </ContextLoading>
    </>
  )
}
