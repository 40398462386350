import styled from 'styled-components/macro'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 220px;

  .gallery-custom-header {
    display: none;
  }

  .image-gallery-fullscreen-button {
    transition: unset;
  }

  .image-gallery-slides {
    border-radius: 10px;
    min-height: 220px;
  }

  .image-gallery-slides .image-gallery-image {
    width: 100%;
    height: auto;
    max-width: 220px;
    max-height: 220px;
    object-fit: cover;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav,
  .image-gallery-fullscreen-button {
    svg {
      width: 15px;
    }

    &:hover {
      color: #eee;
    }
  }

  .image-gallery-download-action {
    display: none;
  }

  .fullscreen-modal {
    .image-gallery-image {
      height: auto;
      max-width: none;
      max-height: calc(100vh - 80px);
    }

    .image-gallery-left-nav,
    .image-gallery-right-nav,
    .image-gallery-fullscreen-button {
      svg {
        width: 25px;
      }
    }

    .image-gallery-download-action {
      display: block;
      color: #fff;
      transition: all 0.2s ease-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      right: 40px;
      bottom: 0;
      padding: 20px;
      appearance: none;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      outline: none;
      position: absolute;
      z-index: 4;
      filter: drop-shadow(0 2px 2px #1a1a1a);

      svg {
        width: 22px;
        height: 36px;
        font-size: 22px;
      }
    }

    &.custom-layout {
      background: #000000ad;

      .image-gallery-content {
        background: none;
        /* max-width: 80vh; */
        width: 70%;
        margin: 0 auto;

        .image-gallery-fullscreen-button {
          transition: unset;
          top: 0;
          bottom: unset;
          padding: 12px;
          color: black;
          font-size: 18px;
          filter: none;
        }

        .image-gallery-fullscreen-button:hover,
        .image-gallery-download-icon:hover {
          opacity: 0.8;
        }

        .gallery-custom-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          position: relative;
          border-radius: 20px 20px 0 0;
          color: #000;
          top: 1px;
          background: #fff;
          height: 40px;
          width: 100%;

          a {
            position: relative;
            text-decoration: none;
            border: none;
            color: #000;
            font-size: 16px;
            font-weight: 300;
            filter: none;

            svg {
              margin-left: 10px;
              font-size: 12px;
            }
          }
        }

        @media (max-width: 768px) {
          width: 85%;
        }
      }
    }
  }
`
