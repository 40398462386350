import i18n from '../../../config/i18n'

import dateFormat from '../../../utils/dateFormat'

import { Product, AssetsTypes, PRODUCT_STATUS } from './types'

export function setProductsData(products: Product[]) {
  if (products.length === 0) return []
  const productsData = products
    .map((product: Product) => ({
      ...product,
      visible: true,
      lastStatusUpdate: dateFormat(
        new Date(product.lastStatusUpdate),
        i18n.t('config:localeDateFormat')
      ),
      referenceImages: product.assets
        .filter((asset) => asset.type === AssetsTypes.REFERENCE)
        .map((asset) => asset.url),
      renderImages: product.assets
        .filter((asset) => asset.type === AssetsTypes.RENDER)
        .map((asset) => asset.url),
      glb: product.assets
        .filter((asset) => asset.type === AssetsTypes.GLB)
        .map((asset) => asset.url)[0],
      thumbnailUrl: product.assets
        .filter((asset) => asset.type === AssetsTypes.GLB)
        .map((asset) => asset.thumbnailUrl)[0],
      usdz: product.assets
        .filter((asset) => asset.type === AssetsTypes.USDZ)
        .map((asset) => asset.url)[0],
    }))
    .sort((product) =>
      product.status === PRODUCT_STATUS.MISSING_INFORMATION ? -1 : 1
    )

  return productsData
}
