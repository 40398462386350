import React from 'react'

import { useTranslation } from 'react-i18next'

import { FaCheck, FaTimes } from 'react-icons/fa'
import { OverlayStyled } from './styles'

import { PRODUCT_STATUS } from '../../../store/modules/product/types'

interface Props {
  effect: string
}

export default function Overlay({ effect }: Props) {
  const { t } = useTranslation(['components/products/reviewItem/overlay'])

  return (
    <OverlayStyled className="status-overlay" effect={effect}>
      <div className="overlay-base">
        <div className="overlay-content">
          {effect === PRODUCT_STATUS.APPROVED ? (
            <>
              <FaCheck size={150} color="#fff" />
              <span>{t('messageApproved')}</span>
            </>
          ) : (
            <>
              <FaTimes size={150} color="#fff" />
              <span>{t('messageRejected')}</span>
            </>
          )}
        </div>
      </div>
    </OverlayStyled>
  )
}
