import { Reducer } from 'redux'
import { UserState, UserTypes } from './types'
import { AuthTypes } from '../auth/types'

const INITIAL_STATE: UserState = {
  id: null,
  type: null,
  name: null,
  email: null,
  customers: null,
  customerId: '',
  termsAndConditionsUrl: '',
  termsAndConditionsId: '',
  acceptTermsAndConditionsLoading: false,
  summary: { pendingReviewsCount: 0, missingInformationsCount: 0 },
}

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.SESSION_SUCCESS: {
      const { user } = action.payload
      return { ...state, ...user }
    }
    case AuthTypes.LOGOUT: {
      return {}
    }
    case UserTypes.ACCEPT_TERMS_REQUEST:
      return { ...state, acceptTermsAndConditionsLoading: true }
    case UserTypes.ACCEPT_TERMS_SUCCESS:
      return {
        ...state,
        termsAndConditionsUrl: '',
        acceptTermsAndConditionsLoading: false,
      }
    case UserTypes.GET_SUMMARY_SUCCESS:
      return { ...state, summary: action.payload.summary }
    case UserTypes.GET_CUSTOMER_SUCCESS:
      return { ...state, customers: [...action.payload.customers] }
    default:
      return state
  }
}

export default reducer
