import React, { ReactNodeArray, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FaTimes } from 'react-icons/fa'

import { ModalContainer, CloseButton } from '../styles'

import { showModal } from '../../../store/modules/modal/actions'

import { ModalContent, ModalBody, ModalFooter } from './styles'
import { ApplicationState } from '../../../store'

interface Props {
  children: ReactNodeArray
  onClose?: () => void
}

export default function ({ children, onClose }: Props) {
  const [body, footer] = children

  const show = useSelector((state: ApplicationState) => state.modal.show)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show) {
      onClose()
    }
  }, [show, onClose])

  return (
    <ModalContainer visible={show}>
      <ModalContent>
        <CloseButton onClick={() => dispatch(showModal(false))}>
          <FaTimes size={20} />
        </CloseButton>
        <ModalBody>{body}</ModalBody>

        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
}
