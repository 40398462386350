import { action } from 'typesafe-actions'
import { ModelTypes } from './types'
import { UpdateStatusItem, Product } from '../product/types'

export const loadModelsRequest = (payload: {
  page: number
  resultsPerPage: number
  status: string
  nameOrSku: string
  customerIdFilter: string
}) => action(ModelTypes.LOAD_MODELS_REQUEST, payload)
export const changeStatus = (payload: { status: string }) =>
  action(ModelTypes.CHANGE_STATUS, payload)
export const loadModelsSuccess = (models: Product[], totalItemsCount: number) =>
  action(ModelTypes.LOAD_MODELS_SUCCESS, { models, totalItemsCount })
export const loadBaseModelsSuccess = (models: Product[]) =>
  action(ModelTypes.LOAD_BASE_MODELS_SUCCESS, { models })
export const searchModels = (payload: {}) =>
  action(ModelTypes.SEARCH_MODELS_REQUEST, payload)
export const updateMultipleModelsStatusRequest = (
  updateStatusData: Array<UpdateStatusItem>
) => action(ModelTypes.UPDATE_MULTIPLE_MODEL_STATUS_REQUEST, updateStatusData)

export const toggleProductRunningOrPaused = (
  status: string,
  productId: string
) =>
  action(ModelTypes.TOGGLE_PRODUCT_RUNNING_OR_PAUSED_REQUEST, {
    status,
    productId,
  })
export const deleteProductRequest = (_id: string, undo = false) =>
  action(ModelTypes.DELETE_PRODUCT_REQUEST, { _id, undo })

export const refreshInMemoryDeletedItensRequest = (_id: string) =>
  action(ModelTypes.REFRESH_IN_MEMORY_DELETED_ITENS_REQUEST, { _id })

export const processFilterToggle = (status: boolean) =>
  action(ModelTypes.PROCESS_FILTER_TOGGLE, { status })
