import React from 'react'
import { NotFoundContainer } from './styles'

export default function NotFound() {
  return (
    <NotFoundContainer>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <h1>PAGE NOT FOUND</h1>
    </NotFoundContainer>
  )
}
