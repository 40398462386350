import React from 'react'

import Toggle from 'react-toggle'
import { PRODUCT_STATUS } from '../../../store/modules/product/types'

import { ToggleStyled } from './styles'

interface Props {
  productStatus: string
  onChange: (status: string) => void
}

const ToggleStatus: React.FC<Props> = ({ productStatus, onChange }: Props) => {
  const isToggleEnabled = ![
    PRODUCT_STATUS.APPROVED,
    PRODUCT_STATUS.RUNNING,
    PRODUCT_STATUS.PAUSED,
  ].includes(productStatus)

  return (
    <ToggleStyled>
      <Toggle
        name="ar-control"
        icons={false}
        disabled={isToggleEnabled}
        onChange={(e) =>
          onChange(
            e.target.checked ? PRODUCT_STATUS.RUNNING : PRODUCT_STATUS.PAUSED
          )
        }
        defaultChecked={productStatus === PRODUCT_STATUS.RUNNING}
      />
    </ToggleStyled>
  )
}

export default ToggleStatus
