import React, { useEffect, useState } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { Row } from 'react-flexbox-grid'
import { useSelector } from 'react-redux'
import { PageInfo, ApproveAllConfirmation } from './styles'
import { ApplicationState } from '../../store'

interface Props {
  showDialog: boolean
  handleApproveAll: () => void
  isVisible: boolean
}

export default function ApproveAllDialog({
  showDialog,
  handleApproveAll,
  isVisible,
}: Props) {
  const { t } = useTranslation(['pendingReviews/approveAllDialog'])

  const [show, setShow] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setShow(true)
      return
    }

    setTimeout(() => {
      setShow(isVisible)
    }, 500)
  }, [isVisible])

  const isUpdating = useSelector(
    (state: ApplicationState) => state.products.isUpdating
  )

  return show ? (
    <Row>
      {!showDialog ? (
        <PageInfo sm={12}>
          <p>
            <Trans i18nKey="pendingReviews/approveAllDialog:info">
              <strong />
              <strong />
              <strong />
              <strong />
            </Trans>
          </p>
        </PageInfo>
      ) : (
        <ApproveAllConfirmation sm={12}>
          <h3>{t('approveAllQuestion')}</h3>
          <button
            className="btn btn-approve"
            type="button"
            onClick={handleApproveAll}
            disabled={isUpdating}
          >
            {t('approveAllConfirmation')}
          </button>
        </ApproveAllConfirmation>
      )}
    </Row>
  ) : (
    <div />
  )
}
