import { AssetsTypes } from '../product/types'

export enum ModalTypes {
  'SHOW_MODAL' = '@modal/SHOW_MODAL',
  'CREATE_PRODUCT_REQUEST' = '@modal/CREATE_PRODUCT_REQUEST',
  'CREATE_PRODUCT_SUCCESS' = '@modal/CREATE_PRODUCT_SUCCESS',
  'CREATE_ASSETS' = '@modal/CREATE_ASSETS',
  'SET_NAME' = '@modal/SET_NAME',
  'SET_SKU' = '@modal/SET_SKU',
  'ADD_FILE' = '@modal/ADD_FILE',
  'REMOVE_FILE' = '@modal/REMOVE_FILE',
  'UPDATE_FILE' = '@modal/UPDATE_FILE',
}

export interface NewProduct {
  customerId: string
  name: string
  sku: string
  urls?: string[]
}

export interface NewAsset {
  productId: string
  url: string
  type: AssetsTypes
  shouldTrigger3DOpsWorkflow: boolean
}

export interface UploadFile {
  file: File
  url: string
  percent: number
}

export interface FileUpdate {
  file: File
  url?: string
  percent?: number
}

export interface ModalState {
  readonly show: boolean
  name: string
  sku: string
  uploadFiles: UploadFile[]
}
