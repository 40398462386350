import { combineReducers } from 'redux'

import auth from './auth'
import filters from './filters'
import user from './user'
import modal from './modal'
import products from './pendingReviews'
import models from './models'
import loading from './loading'

export default combineReducers({
  auth,
  filters,
  user,
  modal,
  products,
  models,
  loading,
})
