import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'

import Header from '../../../components/Header'
import { TermsAndConditionsModal } from '../../../components/Modal'

import { ApplicationState } from '../../../store'

interface Props {
  children: ReactNode
}

export default function (props: Props) {
  const termsAndConditionsUrl = useSelector(
    (state: ApplicationState) => state.user.termsAndConditionsUrl
  )
  const { children } = props
  return (
    <>
      <Header />
      {termsAndConditionsUrl && (
        <TermsAndConditionsModal url={termsAndConditionsUrl} />
      )}
      {children}
    </>
  )
}
