import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { FiSearch } from 'react-icons/fi'
import { SubHeaderStyled, TextInput } from './styles'
import ContextLoading from '../../components/Loading/ContextLoading'
import { ApplicationState } from '../../store'
import { getCustomersRequest } from '../../store/modules/user/actions'
import CustomerFilter from '../../components/Admin/CustomerFilter'
import { setNameOrSku } from '../../store/modules/filters/actions'

interface Props {
  handleApproveAll: () => void
  enableApproveAll: boolean
  active: boolean
}

export default function SubHeader({
  handleApproveAll,
  enableApproveAll,
  active,
}: Props) {
  const { t, ready } = useTranslation(['pendingReviews/subHeader'])

  const [nameOrSkuInput, setNameOrSkuInput] = useState('')
  const customerLimitFilter = 1000
  const { type: userType } = useSelector(
    (state: ApplicationState) => state.user
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCustomersRequest({ limit: customerLimitFilter }))
  }, [dispatch])

  return (
    <ContextLoading isLoading={!ready}>
      <SubHeaderStyled fluid active={active ? 1 : 0}>
        <div className="container">
          <CustomerFilter />
          {userType !== 'CLIENT' && (
            <div className="filter">
              <TextInput>
                <input
                  placeholder={t('pendingReviews/subHeader:search')}
                  type="text"
                  onChange={(e) => setNameOrSkuInput(e.target.value)}
                  onKeyPress={(e) =>
                    e.charCode === 13 && dispatch(setNameOrSku(nameOrSkuInput))
                  }
                  value={nameOrSkuInput}
                />
                <button
                  type="button"
                  className="transparent"
                  onClick={() => dispatch(setNameOrSku(nameOrSkuInput))}
                >
                  <FiSearch />
                </button>
              </TextInput>
            </div>
          )}
        </div>
        <div className="actions">
          <h3>{t('title')}</h3>
          <button
            type="button"
            className="btn-approve-outline"
            onClick={handleApproveAll}
            disabled={enableApproveAll}
          >
            {!active ? t('approveAllBtn') : t('cancelApproveAllBtn')}
          </button>
        </div>
      </SubHeaderStyled>
    </ContextLoading>
  )
}
