export enum ProductReviewTypes {
  'UPDATE_PRODUCT_STATUS_REQUEST' = '@product/UPDATE_PRODUCT_STATUS_REQUEST',
  'UPDATE_MULTIPLE_PRODUCT_STATUS_REQUEST' = '@product/UPDATE_MULTIPLE_PRODUCT_STATUS_REQUEST',
  'UPDATE_PRODUCT_STATUS_SUCCESS' = '@product/UPDATE_PRODUCT_STATUS_SUCCESS',
}

export enum ServiceTypes {
  AUGMENTED_REALITY = 'AUGMENTED_REALITY',
  CGI = 'CGI',
  BOTH = 'BOTH',
}

export enum AssetsTypes {
  REFERENCE = 'REFERENCE',
  RENDER = 'RENDER',
  GLB = 'GLB',
  USDZ = 'USDZ',
  SKP = 'SKP',
}

interface Assets {
  _id: string
  url: string
  type: string
  thumbnailUrl: string
}

export interface Product {
  _id: string
  customerId: string
  sku: string
  url: string
  name: string
  status?: string
  lastStatusUpdate: string
  height?: string
  width?: string
  depth?: string
  updated: string
  customerSlug?: string
  serviceType?: string
  assets?: Array<Assets>
  referenceImages: string[]
  renderImages: string[]
  glb: string
  usdz: string
  visible?: boolean
  deleted?: boolean
  removalStatus?: string
  thumbnailUrl?: string
}

export const REJECT_REASONS = {
  WRONG_TEXTURE: 'WRONG_TEXTURE',
  WRONG_COLOR: 'WRONG_COLOR',
  TRANSPARENT_FACES: 'TRANSPARENT_FACES',
  BAD_MODELING: 'BAD_MODELING',
  WRONG_SCALE: 'WRONG_SCALE',
  WRONG_PIVOT_POINT: 'WRONG_PIVOT_POINT',
  OTHERS: 'OTHERS',
}

export interface RejectData {
  comments?: string
  reason?: string
  isValidData: boolean
}

export const PRODUCT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  EXTERNAL_REVIEW: 'EXTERNAL_REVIEW',
  MISSING_INFORMATION: 'MISSING_INFORMATION',
  READY_TO_START: 'READY_TO_START',
  IN_PROGRESS: 'IN_PROGRESS',
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
}

export interface UpdateStatusItem {
  productId: string
  status: string
  reason?: string
  comments?: string
}
