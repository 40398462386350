import React, { ElementType } from 'react'
import { Route, Redirect } from 'react-router-dom'

import DefaultLayout from '../pages/_layouts/default'
import AuthLayout from '../pages/_layouts/auth'

import { store } from '../store'

interface Props {
  component: ElementType
  isPrivate?: boolean
  exact?: boolean
  path: string
}

export default function RouteWrapper({
  component: Component,
  isPrivate = true,
  ...rest
}: Props) {
  const { signed } = store.getState().auth

  if (!signed && isPrivate) {
    return <Redirect to="/login" />
  }

  const Layout = signed ? DefaultLayout : AuthLayout

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  )
}
