import styled from 'styled-components'

export const Container = styled.div`
  width: 380px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;

  p {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #1f2f3b;
  }

  .file-name {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  div {
    .progress-bar {
      height: 10px;
      width: 100px;
      border-radius: 10px;
      margin-right: 10px;
    }
    .unset-file-icon {
      left: 0;
      padding: 0;
    }
  }
`
